import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/apiCalls/userService/user.service';
import {UtilService} from '../../services/utilService/util.service';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {IResponse} from '../../interfaces/iresponse';
import {CacheService} from '../../services/cacheService/cache.service';
import {NavigatorService} from '../../services/navigatorService/navigator.service';
import {ActivatedRoute} from '@angular/router';
import {RedirectService} from '../../services/redirectService/redirect.service';

@Component({
  selector: 'app-checkout-review',
  templateUrl: './checkout-review.component.html',
  styleUrls: ['./checkout-review.component.css']
})
export class CheckoutReviewComponent implements OnInit {
  currentUser = null;
  loadingCoupon = false;
  loadingVoucher = false;
  checkingOut = false;
  couponData = null;
  voucherData = null;
  formData: any = null;
  loaders = {
    ordering: false
  };
  selectedAddressInfo = null;
  cartItems = [];
  displayModes = [];
  payOnDelivery = [];
  loading = true;
  totalInCart: any = 0.0;
  totalOrder: any = 0.0;
  taxTotal: any = 0.0;
  voucherCode = null;
  addressBooks = [];
  couponCode = null;
  paymentMode: any = 'online';
  paymentModes = [];
  selectedPaymentGateway = null;
  walletBalance = 0.0;
  transactionRef = null;
  paymentId = null;
  paymentWebPayId = null;
  storageInfo = {selectedAddressInfo: null, paymentType: null, customer_id: null, paymentMode: 'online'};
  constructor(private userService: UserService, private utilService: UtilService,
              private cacheService: CacheService,
              private navigatorService: NavigatorService,
              private route: ActivatedRoute,
              private redirect: RedirectService,
              private alertService: BootstrapNotifyService) {
    this.currentUser =  this.utilService.getAuthUser();
    this.getWalletBalance();
    this.storageInfo = JSON.parse(this.cacheService.getStorage('DELIVERY_DATA'));
    this.listPaymentModes();
    this.paymentId = this.route.snapshot.paramMap.get('paymentId') || null;
    this.paymentWebPayId = this.route.snapshot.queryParamMap.get('txn_ref') || null;
    // this.paymentId = this.route.snapshot.paramMap.get('paymentId') || null;
    if (this.paymentId && this.paymentId !== '') {
      this.verifyPayment();
    }
    if (this.paymentId && this.paymentId === '12345678901234567890') {
      this.verifyPayment('webPay');
    }
  }

  ngOnInit() {
    this.getCartItems();
    this.selectedAddressInfo = this.storageInfo && this.storageInfo.selectedAddressInfo;
    this.paymentMode = this.storageInfo && this.storageInfo.paymentMode;
    if (!this.selectedAddressInfo || this.currentUser.customer_id !== this.storageInfo.customer_id) {
      this.getAddressBooks();
    } else {}
  }
  getWalletBalance() {
    this.userService.getWallet(this.currentUser.customer_id).subscribe((res: IResponse) => {
      this.walletBalance = res.data;
    }, error => {
      this.alertService.error('Unable to get wallet balance');
    });
  }

  getCartItems() {
    this.loading = true;
    this.cartItems = [];
    this.totalInCart = this.totalOrder = this.taxTotal = 0;
    this.userService.getCartItems({customer_id: this.currentUser.customer_id})
      .subscribe((res: IResponse) => {
        console.log('Res Cart ', res);
        this.cartItems = res.data;
        this.loading = false;
        if (this.cartItems.length === 0 ) {
          this.alertService.error('You can\'t view page because, no items in cart');
          this.navigatorService.navigateUrl('/');
          return;
        } else {
          this.cartItems.forEach((cart, i) => {
            // cart.total_ = parseFloat(cart.price) * parseInt(cart.quantity, 10);
            this.totalInCart += parseFloat(cart.total || 0 as any);
            this.taxTotal += parseFloat(cart.tax || 0);
          });
          this.totalOrder = this.totalInCart + parseFloat(this.taxTotal) + parseFloat(this.selectedAddressInfo && this.selectedAddressInfo.amount || 0);
        }
      }, error => {
        console.log('Cart Error ', error);
        this.loading = false;
      });
  }
  getAddressBooks() {
    this.addressBooks = [];
    this.userService.getAddressBooks(this.currentUser.customer_id)
      .subscribe((res: IResponse) => {
        console.log('Res Books Address ', res);
        this.addressBooks = res.data || [];
        this.selectedAddressInfo = this.addressBooks[0];
        this.totalOrder = this.totalInCart + parseFloat(this.selectedAddressInfo && this.selectedAddressInfo.amount || 0);
      }, error => {});
  }
  placeOrder() {
    console.log('this.paymentMode', this.paymentMode);
    if (this.paymentMode === 'online') {
      this.utilService.openModal('SelectPaymentGateway');
    } else {
      this.selectedPaymentGateway = this.payOnDelivery[0];
      this.proceedToPayment();
    }
  }
  proceedToPayment() {
    if (this.cartItems.length < 1) {
      this.navigatorService.navigateUrl('/');
    }
    console.log('Selected payment ', this.selectedPaymentGateway);
    this.loaders.ordering = true;
    this.alertService.info('Order processing in progress');
    this.storageInfo.paymentMode = this.paymentMode;
    const redirect = window.location.href + '/';
    this.cacheService.setStorage('DELIVERY_DATA', JSON.stringify(this.storageInfo));
   /* if (this.selectedPaymentGateway.name.toLowerCase().includes('webpay')) {
      redirect = window.location.href + '/12345678901234567890';
    }*/
    this.userService.createInvoice({
      customer_id: this.currentUser.customer_id,
      callback_url: redirect,
      coupon_id: this.couponData && this.couponData.coupon_id,
      paymentmode_id: this.selectedPaymentGateway.paymentmode_id,
      shippingmethod_id: (this.storageInfo.paymentType === 'doorDelivery') ? 2 : 1,
      shippingaddress_id: (this.storageInfo.paymentType === 'doorDelivery') ? this.selectedAddressInfo.shippingaddress_id : ''}
      ).subscribe((res: IResponse) => {
      console.log('RESc ', res);
      this.loaders.ordering = false;
      this.alertService.success(res.message || 'Order placed successfully!');
      this.utilService.closeModal('SelectPaymentGateway');
      if (this.selectedPaymentGateway.name.toLowerCase().includes('paystack')) {
        this.cacheService.setStorage('TRANSACTION_REFERENCE', res.data.reference);
        window.location.assign(res.data.authorization_url);
      } else if (this.paymentMode === 'pay_on_delivery') {
        window.location.href = window.location.origin + '/user/my-orders';
        // this.navigatorService.navigateUrl('/user/my-orders');
      } else if (this.selectedPaymentGateway.name.toLowerCase().includes('wallet')) {
        this.alertService.success('Order placed successfully');
        window.location.href = window.location.origin + '/user/my-orders';
      } else if (this.selectedPaymentGateway.name.toLowerCase().includes('webpay')) {
        console.log('RESc ', res);
        this.cacheService.setStorage('TRANSACTION_REFERENCE', res.data.fields.txn_ref);
        this.redirect.post(res.data.fields, res.data.payment_url);
      } else {
        this.alertService.info('Unable to redirect to payment gateway');
      }
    }, error => {
      this.loaders.ordering = false;
      console.log({error});
      this.utilService.closeModal('SelectPaymentGateway');
      this.alertService.error(error.error.message || 'Unable to create your order!');
      this.getCartItems();
    });
  }
  private verifyPayment(type = null) {
    if (type === 'webPay') {
      this.userService.verifyWebPayPayment({transaction_ref: this.paymentWebPayId, customer_id: this.currentUser.customer_id})
        .subscribe((res: IResponse) => {
          this.alertService.success(res.message || 'Payment verified successfully!');
          // this.navigatorService.navigateUrl('/user/my-orders');
          this.cacheService.deleteStorage('TRANSACTION_REFERENCE');
          window.location.href = window.location.origin + '/user/my-orders';
        }, error => {
          this.alertService.error(error.error.msg || 'Unable to verify transaction payment');
        });
    } else {
      this.userService.verifyPayment({transaction_ref: this.paymentId, customer_id: this.currentUser.customer_id})
      .subscribe((res: IResponse) => {
        this.alertService.success(res.message || 'Payment verified successfully!');
        // this.navigatorService.navigateUrl('/user/my-orders');
        this.cacheService.deleteStorage('TRANSACTION_REFERENCE');
        window.location.href = window.location.origin + '/user/my-orders';
      }, error => {
        this.alertService.error(error.error.msg || 'Unable to verify transaction payment');
      });
    }
  }
  listPaymentModes() {
    this.userService.listPaymentModes().subscribe((res: IResponse) => {
      console.log('RES ', res);
      // this.paymentModes = res.data;
      this.paymentModes = res.data.filter(mode => mode.status == 1);

      // this.displayModes = this.paymentModes.filter(mode => mode.name.toLowerCase() !== 'cash on delivery');
      this.displayModes = this.paymentModes;
      this.payOnDelivery = this.paymentModes.filter(mode => mode.name.toLowerCase() === 'cash on delivery');
      if (this.walletBalance === 0) {
        this.payOnDelivery = this.paymentModes.filter(mode => mode.name.toLowerCase() !== 'wallet');
      }
    }, error => {
      console.log({error});
     setTimeout(() => {
       this.listPaymentModes();
     }, 3000);
    });
  }
  applyCoupon() {
    this.checkingOut = true;
    this.userService.applyCoupon({
      customer_id: this.currentUser.customer_id,
      code: this.couponCode
    }).subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.checkingOut = false;
      this.couponData = res.data;
      this.voucherData = null
      this.totalOrder = this.couponData.ordertotal;
      this.alertService.success(res.message || 'Coupon applied successful!');
    }, error => {
      this.checkingOut = false;
      this.couponData = null;
      this.alertService.error(error.error.message || 'Unable to apply this code!');
    });
  }
  applyVoucher() {
    this.checkingOut = true;
    this.userService.applyVoucher({
      customer_id: this.currentUser.customer_id,
      code: this.voucherCode
    }).subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.couponData = null;
      this.checkingOut = false;
      this.voucherData = res.data;
      this.totalOrder = this.voucherData.ordertotal;
      this.alertService.success(res.message || 'Voucher code applied successful!');
    }, error => {
      this.checkingOut = false;
      this.voucherData = null;
      this.alertService.error(error.error.message || 'Unable to apply this voucher code!');
    });
  }
}
