import { Component, OnInit } from '@angular/core';
import {UtilService} from "../../../../services/utilService/util.service";
import {AuthService} from "../../../../services/authService/auth.service";
import {IResponse} from "../../../../interfaces/iresponse";
import {UserService} from "../../../../services/apiCalls/userService/user.service";

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {
  currentUser = null;
  categories = [];
  categories_with_no_sub = [];
  categories_ = [];
  constructor(private utilService: UtilService, private authService: AuthService,
              private userService: UserService) {
    this.getCategories();
  }

  ngOnInit() {
    this.currentUser =  this.utilService.getAuthUser();
  }
  logout() {
   this.authService.logUserOut();
  }
  close() {
    $('.loaded').removeClass('mmenu-active');
  }
  getCategories() {
    this.categories = [];
    this.userService.getMegaCategories()
      .subscribe((res: IResponse) => {
        console.log('Res Category ', res);
        this.categories = res.data;
        // const categories = this.categories.filter(cat => cat.subcategory.length);
        // const categories_ = this.categories.filter(cat => cat.subcategory.length === 0);
        // this.categories_  = categories.slice(0, 6);
        // this.categories_with_no_sub  = categories_.slice(0, 3);
      }, error => {
        this.categories = [];
      });
  }
}
