import { Component, OnInit } from '@angular/core';
import {NavigatorService} from '../../../services/navigatorService/navigator.service';
import {UserService} from '../../../services/apiCalls/userService/user.service';
import {BootstrapNotifyService} from '../../../services/bootstrap-notify/bootstrap-notify.service';
import {UtilService} from '../../../services/utilService/util.service';
import {IResponse} from '../../../interfaces/iresponse';

@Component({
  selector: 'app-feature-products',
  templateUrl: './feature-products.component.html',
  styleUrls: ['./feature-products.component.css']
})
export class FeatureProductsComponent implements OnInit {
  loading = true;
  featuredProducts: any[] = [];
  Products: any[] = [];
  currentUser = null;
  constructor(private navigatorService: NavigatorService,
              private userService: UserService,
              private alertService: BootstrapNotifyService,
              private utilService: UtilService) {
    this.getProducts();
  }

  ngOnInit() {
    this.currentUser =  this.utilService.getAuthUser();
  }
  getProducts() {
    this.loading = true;
    this.userService.getFeaturedProducts()
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.Products = res.data;
        // this.featuredProducts = this.utilService.shuffle(this.Products).slice(0, 6);
        this.featuredProducts = this.Products.slice(0, 6);
        console.log('featuredProducts ', this.featuredProducts);
        this.loading = false;
      }, error => {
        console.log('Error ', error);
        this.loading = false;
      });
  }

  addToWishlist(product) {
    this.utilService.addToWishlist(product);
  }
  addToCart(product) {
    this.utilService.addToCart(product);
  }

}
