import {AfterViewInit, Component, OnInit} from '@angular/core';
// import * as $ from 'jquery';
import Wow from 'wow.js';
import {NavigatorService} from '../../services/navigatorService/navigator.service';
import {IResponse} from '../../interfaces/iresponse';
import {UserService} from '../../services/apiCalls/userService/user.service';
import {UtilService} from '../../services/utilService/util.service';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {Router} from "@angular/router";
// import 'owl.carousel';
declare var $: any;
// declare var jQuery: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
  loading = false;
  mobile = false;
  Products: any[] = [];
  allProducts: any[] = [];
  // featuredProducts: any[] = [];
  // bestSeller: any[] = [];
  // childrenSection: any[] = [];
  // businessSection: any[] = [];
  // christianSection: any[] = [];
  // newArrival: any[] = [];
  categories = [];
  currentUser = null;
  constructor(private navigatorService: NavigatorService,
              private userService: UserService,
              private router: Router,
              private alertService: BootstrapNotifyService,
              private utilService: UtilService) {
    this.getProducts();
    this.getCategories();
  }

  ngOnInit() {
    // this.getBestsellers();
    // this.getNewArrivals();
    $('#page-loader').fadeOut('fast', function() {
      $(this).remove();
    });
    this.currentUser =  this.utilService.getAuthUser();

  }
  ngAfterViewInit() {
    const wow: any = new Wow({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 50,
      mobile: false,
      live: true
    });
    wow.init();
    setTimeout(() => {
      $(('.testimonial-slides') as any).owlCarousel({
        singleItem: true,
        items: 1,
        autoplay: true,
        loop: true,
        smartSpeed: 1000,
        dots: true,
        pagination: true,
      });
    }, 100);
this.homeSlider();
  }
  homeSlider() {
    const sliderDefaultOptions = {
      loop: true,
      margin: 0,
      responsiveClass: true,
      nav: false,
      navText: ['<i class="icon-left-open-big">', '<i class="icon-right-open-big">'],
      dots: true,
      autoplay: true,
      autoplayTimeout: 15000,
      items: 1,
    };

    // Init all carousel
    $('[data-toggle="owl"]').each(function () {

      let pluginOptions = $(this).data('owl-options');

      if (typeof pluginOptions === 'string') {
        pluginOptions = JSON.parse(pluginOptions.replace(/'/g, '"').replace(';', ''));
      }

      const  newOwlSettings = $.extend(true, {}, sliderDefaultOptions, pluginOptions);

      const owlIns = $(this).owlCarousel(newOwlSettings);
    });

    /* Hom Slider */
    const homeSlider = $('.home-slider');
    const homeSliderSidebar = $('.home-slider-sidebar ul');

    homeSlider.owlCarousel($.extend(true, {}, sliderDefaultOptions, {
      lazyLoad: true,
      autoplayTimeout: 20000,
      animateOut: 'fadeOut',
      dots : true
    }));
    homeSlider.on('loaded.owl.lazy', function (event) {
      $(event.element).closest('.home-slider').addClass('loaded');
    }).on('translate.owl.carousel', function(event) {
      homeSliderSidebar.children('.active').removeClass('active');
      homeSliderSidebar.children('li:nth-child(' + ((event.item.index + 1) % 3 + 1) + ')').addClass('active');
    });
    $('.home-slider-sidebar li').on('click', function(event) {
      if (!$(this).hasClass('active')) {
        const index = $(this).index();
        $('.home-slider').trigger('to.owl.carousel', [index, 300, true]);
      }
    });

  }


/*

  getBestsellers() {
    this.userService.getBestSellersProducts().subscribe((res: IResponse) => {
      console.log('RES BST SELLER ', res);
      this.bestSeller = this.utilService.shuffle(JSON.parse(JSON.stringify(res.data))).slice(0, 4);
    }, error => {
      console.log('BEST SELLER Error ', error);
    } );
  }
*/

/*
  getNewArrivals() {
    this.userService.getLatestArrival().subscribe((res: IResponse) => {
      console.log('RES LATEST ', res);
      this.newArrival = this.utilService.shuffle(JSON.parse(JSON.stringify(res.data))).slice(0, 4);
    }, error => {
      console.log('Lastest Arrival Error ', error);
    } );
  }*/

  getProducts() {
    this.loading = true;
    this.userService.getBestSellersProducts()
      .subscribe((res: IResponse) => {
        console.log('Response 96543456789', res);
        this.Products = res.data;
        // this.allProducts = this.utilService.shuffle(JSON.parse(JSON.stringify(this.Products))).slice(0, 12);
        this.allProducts = JSON.parse(JSON.stringify(this.Products)).slice(0, 12);
        // console.log('featuredProducts ', this.featuredProducts);
        this.loading = false;
      }, error => {
        console.log('Error ', error);
        this.loading = false;
      });
  }
  addToWishlist(product) {
    this.utilService.addToWishlist(product);
  }
  addToCart(product) {
    this.utilService.addToCart(product);
  }
  gotoAllProducts(cat_id = null) {
    this.router.navigate(['/search-filter-products'], { queryParams: { categoryId: cat_id }});
    // this.navigatorService.navigateUrl('/search-filter-products?categoryId=' + cat_id);
  }

  viewAll(url) {
    this.navigatorService.navigateUrl(url);
  }
  getCategories() {
    this.userService.getCategories().subscribe((res: IResponse) => {
      console.log('Categories ', res);
      this.categories = res.data;
      setTimeout(() => {
        $(('.category-slider') as any).owlCarousel({
          'loop' : true,
          'nav': false,
          'dots': true,
          'margin': 20,
          'autoHeight': true,
          'autoplay': true,
          'autoplayTimeout': 5000,
          'responsive': {
            '0': {
              'items': 2
            },
            '570': {
              'items': 2
            },
            '830': {
              'items': 3
            },
            '1220': {
              'items': 4
            }
          }
        });
      }, 100);
    }, error => {
      console.log({error});
    });
  }

  getProductsByCategory(category) {
    this.loading = true;
    this.userService.getBestSellerByCategory(category)
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.Products = res.data;
        // this.allProducts = this.utilService.shuffle(JSON.parse(JSON.stringify(this.Products))).slice(0, 12);
        this.allProducts = JSON.parse(JSON.stringify(this.Products)).slice(0, 12);
        console.log('BEST SELLERS ', this.allProducts);
        this.loading = false;
      }, error => {
        console.log('Error ', error);
        this.alertService.error(error.error.message);
        this.loading = false;
      });
  }
  /*filterByCategory() {
    const category = $('#categorySort').val();
    this.getProductsByCategory(category);
  }*/
  filterByCategory() {
    const category = $('#categorySort1').val();
    if (category === 'menu_order') {
      this.getProducts();
    } else {
      this.getProductsByCategory(category);
    }
  }
}
