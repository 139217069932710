import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  breadCrumb: any;
  public dashboard: any = null;
  constructor() { }

  ngOnInit() {
    this.breadCrumb  = {
      name: 'Dashboard',
      parent: 'Home',
      subLink: null
    };
    this.setDashboard();
  }

  public setDashboard() {
    /*this.superAdminService.getDashboard()
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.dashboard = res.data;
      }, error => {
        console.log('Error ', error);
      });*/
  }

}
