import { Component, OnInit } from '@angular/core';
import {NavigatorService} from "../../services/navigatorService/navigator.service";
import {BootstrapNotifyService} from "../../services/bootstrap-notify/bootstrap-notify.service";
import {UserService} from "../../services/apiCalls/userService/user.service";
import {UtilService} from "../../services/utilService/util.service";
import {IResponse} from "../../interfaces/iresponse";

@Component({
  selector: 'app-my-wishlist',
  templateUrl: './my-wishlist.component.html',
  styleUrls: ['./my-wishlist.component.css']
})
export class MyWishlistComponent implements OnInit {
  breadCrumb = null;
  loading = true;
  wishListProducts: any[] = [];
  Products: any[] = [];
  currentUser = null;
  constructor(private navigatorService: NavigatorService,
              private userService: UserService,
              private alertService: BootstrapNotifyService,
              private utilService: UtilService) {
  }
  ngOnInit() {
    this.breadCrumb  = {
      name: 'Wishlists',
      parent: 'Home',
      subLink: null
    };
    this.currentUser =  this.utilService.getAuthUser();
    if(this.currentUser) {this.getWishLists();}
  }
  getWishLists() {
    if(this.currentUser) {
      this.loading = true;
    this.wishListProducts  = [];
    this.userService.getWishLists(this.currentUser.customer_id)
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        // const wishListProducts = res.data;
        this.wishListProducts = res.data;
        this.loading = false;
      }, error => {
        this.wishListProducts = [];
        console.log('Error ', error);
        this.loading = false;
      }); }
  }
  addToWishlist(product) {
    this.utilService.addToWishlist(product);
  }
  addToCart(product) {
    this.alertService.info('Adding Item to cart');
    this.utilService.addToCart({product}, 1, () => {
      this.alertService.success('Product added successfully!');
    });
  }
  clearList() {
    this.loading = true;
    this.userService.emptyWishLists(this.currentUser.customer_id).subscribe((res: IResponse) => {
      this.loading = false;
      this.getWishLists();
    }, error => {
      this.loading = false;
    });
  }
  removeFromWish(item) {
    console.log('Item ', item);
    this.userService.removeWishList(item.wishlist_id).subscribe((res: IResponse) => {
      this.getWishLists();
    }, error => {
      this.alertService.error(error.error.message || 'Unable to remove product from wishlist');
    });
  }
}
