import { Component, OnInit } from '@angular/core';
import { environment as ENV } from '../../../environments/environment';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {IResponse} from '../../interfaces/iresponse';
import {NavigatorService} from '../../services/navigatorService/navigator.service';
import * as JWT_DECODE from 'jwt-decode';
import {CacheService} from '../../services/cacheService/cache.service';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {AuthService} from '../../services/authService/auth.service';
import {UserService} from '../../services/apiCalls/userService/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  public EMAIL_VALIDATION: any =  ENV.EMAIL_VALIDATION;
  public credentials = {
    email: null,
    redirect_url: window.location.origin
  };
  public userDetails: any;
  loaders = {
    loading: false
  };
  constructor(private bootstrapNotify: BootstrapNotifyService,
              private userService: UserService,
              private navigatorService: NavigatorService,
              private route: ActivatedRoute,
              private authService: AuthService,
              private cacheService: CacheService) {

  }
  ngOnInit(): void {
    this.userDetails = null;
  }
  public forgotPassword() {
    this.loaders.loading = true;
    if (!this.credentials.email ) {
      this.bootstrapNotify.info('You have to provide email address to reset password!');
      this.loaders.loading = false;
      return;
    } else if (!this.credentials.email.match(this.EMAIL_VALIDATION) ) {
      this.bootstrapNotify.info('Not a valid email address!');
      this.loaders.loading = false;
      return;
    } else {
      this.credentials.redirect_url = window.location.origin + '/reset-password/token';
      this.userService.forgotPassword(this.credentials).subscribe((response: IResponse) => {
        console.log('Response - email', response);
        this.loaders.loading = false;
        this.bootstrapNotify.success(response['msg'] || 'Password reset link sent to you at ' + this.credentials.email);
        this.navigatorService.navigateUrl('/');
      }, error => {
        this.bootstrapNotify.error(error.error.msg || 'Unable to reset password', 'right');
        this.loaders.loading = false;
        console.info('Error => ', error);
      });
    }
  }
}
