import { Component, OnInit } from '@angular/core';
import { environment as ENV } from '../../../environments/environment';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {IResponse} from '../../interfaces/iresponse';
import {NavigatorService} from '../../services/navigatorService/navigator.service';
import * as JWT_DECODE from 'jwt-decode';
import {CacheService} from '../../services/cacheService/cache.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/authService/auth.service';
import {UserService} from '../../services/apiCalls/userService/user.service';
import {EventsService} from '../../services/eventServices/event.service';
import {UtilService} from '../../services/utilService/util.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})


export class RegisterComponent implements OnInit {
  public EMAIL_VALIDATION: any =  ENV.EMAIL_VALIDATION;
  public user = {
    lastname: null,
    firstname: null,
    telephonenumber: null,
    country_id: 156,
    zone_id: 2412,
    countrycode: 234,
    address: null,
    city: null,
    email: null,
    password: null,
    accept_term: false,
    authMode: 'normal',
    access_token: null,
    accessToken: null,
    auth_type: null,
  };
  public userDetails: any;
  loaders = {
    login: false,
    showResetLink: false
  };
  changeCountry = false;
  continueSignup = false;
  public allCountries: any[] = [];
  public allZones: any[] = [];
  private endpoint = "createCustomer";
  constructor(private bootstrapNotify: BootstrapNotifyService,
              private userService: UserService,
              private utilService: UtilService,
              private cacheService: CacheService,
              private navigateService: NavigatorService,
              private router: Router,
              private eventService: EventsService,
              private authService: AuthService,
              private activeRoute: ActivatedRoute) {
    const newsLetter = this.cacheService.getSession('newLetterPopup');
    const wished_product = this.cacheService.getSession('WISHLIST');
    const cart_product = this.cacheService.getSession('CART');
    this.authService.logOut();
    this.cacheService.setSession('newLetterPopup', newsLetter);
    this.cacheService.setSession('WISHLIST', wished_product);
    this.cacheService.setSession('CART', cart_product);
    this.eventService.on('SOCIAL_SIGNUP', (user) => {
      console.log('process signup', user);
      this.bootstrapNotify.info('Fill in missing fields to complete sign up!');
      this.user = user;
    });
  }
  ngOnInit(): void {
    this.userDetails = null;
    this.getCountries();
    this.getZones();
  }


  public signupCustomer() {
    this.loaders.login = true;
    this.cacheService.deleteSession(ENV.TOKEN);
    this.cacheService.deleteStorage(ENV.TOKEN);
    this.cacheService.deleteStorage(ENV.USERTOKEN);
    this.cacheService.deleteSession(ENV.USERTOKEN);
    if (!this.user.email) {
      this.bootstrapNotify.info('Email is required');
      this.loaders.login = false;
    } else if (!this.user.telephonenumber) {
      this.bootstrapNotify.info('Phone Number is required');
      this.loaders.login = false;
    } else if (!this.user.firstname) {
      this.bootstrapNotify.info('First Name is required');
      this.loaders.login = false;
    } else if (!this.user.lastname) {
      this.bootstrapNotify.info('Last Name is required');
      this.loaders.login = false;
    } else if (this.user.auth_type !== 'GOOGLE' && this.user.auth_type !== 'FACEBOOK' && !this.user.password) {
      this.bootstrapNotify.info('Password field is required!');
      this.loaders.login = false;
    } else if (!this.user.country_id) {
      this.bootstrapNotify.info('Select country!');
      this.loaders.login = false;
    } else if (!this.user.zone_id) {
      this.bootstrapNotify.info('Select your zone!');
      this.loaders.login = false;
    } else {
      // this.user.telephonenumber = '234' + this.user.telephonenumber;
      if(this.user.access_token) {
        this.endpoint = "social_register";
      } else {
        this.endpoint = "createCustomer";
      }
      this.userService[this.endpoint](this.user).subscribe((response: IResponse) => {
        console.log('Response', response);
        this.loaders.login = false;
        this.resetSignup();
        // this.navigateService.navigateUrl(`/login`);
        this.bootstrapNotify.success('Registration successful, please login');
        if (response.data.role.toLowerCase() !== 'customer') {
          this.bootstrapNotify.error('Account is not registered as Customer');
          this.authService.logOut();
        } else {
          const wished_product = JSON.parse(this.cacheService.getSession('WISHLIST')) || null;
          const cart_product = JSON.parse(this.cacheService.getSession('CART')) || [];
          this.bootstrapNotify.success(response.message || 'Registration successful!');
          if (cart_product.length || wished_product) {
            this.utilService.addBulkToCart(() => {
              this.utilService.addToWishlist(wished_product);
              this.cacheService.deleteSession('WISHLIST');
            });
          } else {
            this.navigateService.navigateUrl(`/user/dashboard`);
          }


        }
      }, error => {
        this.loaders.login = false;
        this.bootstrapNotify.advance(error.error.message || 'Unable to register at this moment, please try again', 'right');
        console.info('Error => ', error);
      });
    }
  }

  public resetSignup() {
    this.user = {
      lastname: null,
      firstname: null,
      telephonenumber: null,
      countrycode: 234,
      country_id: 156,
      zone_id: 2412,
      address: null,
      city: null,
      email: null,
      password: null,
      accept_term: false,
      authMode: 'normal',
      access_token: null,
      accessToken: null,
      auth_type: null,
    };
  }

  public getCountries() {
    this.userService.getCountries().subscribe(res => {
      console.log('Resp ', res);
      this.allCountries = res.data;
    }, error => {
      console.log('Error ', error);
      this.bootstrapNotify.error('Unable to list countries');
    });
  }

  public getZones(countryId = this.user.country_id) {
    this.changeCountry = false;
    this.userService.getCountryZones(countryId).subscribe(res => {
      console.log('Resp ', res);
      this.allZones = res.data;
      this.changeCountry = true;
    }, error => {
      console.log('Error ', error);
    });
  }
  proceedWithSignup() {
    this.continueSignup = true;
  }
  public socialLogout() {
    this.eventService.broadcast('SOCIALLOGOUT', null);
    this.navigateService.navigateUrl('/login');
  }
}
