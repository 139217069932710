import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-sparkline',
  templateUrl: './sparkline.component.html',
  styleUrls: ['./sparkline.component.css']
})
export class SparklineComponent implements OnInit {
@Input() data: number[] = [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0];
  constructor() { }

  ngOnInit() {
  }

}
