import { Component, OnInit } from '@angular/core';
import {UtilService} from "../../services/utilService/util.service";
import {IResponse} from "../../interfaces/iresponse";
import {UserService} from "../../services/apiCalls/userService/user.service";

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})
export class UserDashboardComponent implements OnInit {
  breadCrumb: any;
  currentUser = null;
  selectedAddressInfo = null;
  addressBooks = [];
  wallet_balance = 0.0;
  constructor(private utilService: UtilService, private userService: UserService) { }

  ngOnInit() {
    this.breadCrumb  = {
      name: 'Dashboard',
      parent: 'Home',
      subLink: null
    };
    this.currentUser =  this.utilService.getAuthUser();
    console.log('Current User ', this.currentUser);
    this.getAddressBooks();
    this.getWallet();
  }
  getAddressBooks() {
    this.addressBooks = [];
    this.userService.getAddressBooks(this.currentUser.customer_id)
      .subscribe((res: IResponse) => {
        console.log('Res Books Address ', res);
        this.addressBooks = res.data || [];
        this.selectedAddressInfo = this.addressBooks[0];
      }, error => {});
  }
  public triggerFundWallet() {
    this.utilService.openModal('fundWallet');
  }
  getWallet() {
    this.userService.getWallet(this.currentUser.customer_id).subscribe((res: IResponse) => {
      this.wallet_balance = res.data;
    }, error => {
      this.wallet_balance = 0;
    });
  }
}
