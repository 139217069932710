import {AfterViewInit, Component, OnInit} from '@angular/core';
import {environment as ENV} from '../../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilService} from '../../../../services/utilService/util.service';
import {AuthService} from '../../../../services/authService/auth.service';
import {EventsService} from '../../../../services/eventServices/event.service';
import {NavigatorService} from '../../../../services/navigatorService/navigator.service';
import {BootstrapNotifyService} from '../../../../services/bootstrap-notify/bootstrap-notify.service';
import {UserService} from '../../../../services/apiCalls/userService/user.service';
import {IResponse} from '../../../../interfaces/iresponse';
import {CacheService} from '../../../../services/cacheService/cache.service';
import {RedirectService} from '../../../../services/redirectService/redirect.service';

declare const $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, AfterViewInit {
  public currentUser: any = null;
  public paymentChannels: any = [];
  selectedPaymentGateway = null;
  public fundWallet = {
    payment_mode: null,
    amount: 0.0,
    url: null
  };
  public loaders = {
    loading: false,
    saving: false
  };
  accountManager = {
    topic: null,
    message: null
};
  wallet = 0.0;
  static updateActiveBar(url) {
    setTimeout(() => {
      console.info('URL : ', url);
      let oldUrl;
      url = url.split('?')[0];
      oldUrl = 'dashboard';
      $('.nav-link-special').removeClass('active');
      $(`#${url}`).addClass('active');
      if ($(`#${url}`).hasClass('active')) {
        oldUrl = url;
      } else {
        $(`#${oldUrl}`).addClass('active');
      }
    }, 500);
  }
  constructor(
    private route: Router,
    private utilService: UtilService,
    private userService: UserService,
    private authService: AuthService,
    private cacheService: CacheService,
    private alertService: BootstrapNotifyService,
    private eventService: EventsService,
    private redirect: RedirectService,
    private navigatorService: NavigatorService
  ) {
    this.getPaymentChannels();
  }
  ngOnInit() {
    this.currentUser = this.utilService.getAuthUser();
    this.getWallet(this.currentUser.customer_id);
  }
  ngAfterViewInit(): void {}
  logout() {
    this.authService.logUserOut();
  }

  public getPaymentChannels() {
    this.userService.listPaymentModes().subscribe((res: IResponse) => {
      this.paymentChannels = res.data.filter(d => d.name.toLowerCase() !== 'wallet' && d.name.toLowerCase() !== 'cash on delivery');
      console.log('PAYMENT CHANNELS ', this.paymentChannels);
      // this.paymentChannels = this.paymentChannels.filter(channel => channel.is_active === true && channel.is_editable);
    }, error => {
      console.log('Unable to get payment Channels');
    });
  }






  public proceedToFund() {
    if (!this.fundWallet.payment_mode) {
      return this.alertService.warning('Select payment channel for this transaction!');
    } else if (this.fundWallet.amount < 0 || this.fundWallet.amount === 0) {
      return this.alertService.warning('Invalid Amount');
    } else {
      this.loaders.saving = true;
      console.log('URL ', window.location);
      this.fundWallet.url = `${window.location.origin}/user/wallet`;
      this.userService.fundWalletInit(this.fundWallet).subscribe((res: IResponse) => {
        this.loaders.saving = false;
        console.log('Walleting ', res);
        this.utilService.closeModal('fundWallet');
        this.alertService.success(res.message || 'Wallet funding initiated successfully');
        this.proceedToGateway(res.data);
        this.eventService.broadcast('RELOAD_HISTORY');
      }, error => {
        this.loaders.saving = false;
        this.alertService.error(error.error.message || 'Unable to fund wallet at this moment, please try again later!', 'right');
      });
    }
  }
  updateChannel(e) {
    if (!e) {
      return false;
    }
    this.selectedPaymentGateway = e;
    console.log('SELECTED GATE ', this.selectedPaymentGateway);
  }
  public proceedToGateway(data) {
    if (this.selectedPaymentGateway.name.toLowerCase().includes('paystack')) {
      this.cacheService.setStorage('TRANSACTION_REFERENCE', data.reference);
      window.location.assign(data.authorization_url);
    } else if (this.selectedPaymentGateway.name.toLowerCase().includes('webpay')) {
      console.log('RESc ', data);
      this.cacheService.setStorage('TRANSACTION_REFERENCE', data.fields.txn_ref);
      this.redirect.post(data.fields, data.payment_url);
    } else {
      this.alertService.info('Unable to redirect to payment gateway');
    }
  }
  public validateAmount() {
    if (Math.sign(this.fundWallet.amount) < 0) {
      this.fundWallet.amount = this.fundWallet.amount * -1;
    } else {
      return;
    }
  }

  public triggerFundWallet() {
    this.getPaymentChannels();
    this.utilService.openModal('fundWallet');
  }

  public getWallet(userId) {
    this.userService.getWallet(userId).subscribe((res: IResponse) => {
      console.log('Wallet Data ', res);
      this.wallet = res.data.balance || 0;
    }, error => {    });
  }
}
