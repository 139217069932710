import { Component, OnInit } from '@angular/core';
import {NavigatorService} from '../../../services/navigatorService/navigator.service';
import {UserService} from '../../../services/apiCalls/userService/user.service';
import {BootstrapNotifyService} from '../../../services/bootstrap-notify/bootstrap-notify.service';
import {UtilService} from '../../../services/utilService/util.service';
import {IResponse} from '../../../interfaces/iresponse';

@Component({
  selector: 'app-latest-arrival',
  templateUrl: './latest-arrival.component.html',
  styleUrls: ['./latest-arrival.component.css']
})
export class LatestArrivalComponent implements OnInit {
  loading = true;
  // featuredProducts: any[] = [];
  latestArrivals: any[] = [];
  Products: any[] = [];
  categories: any[] = [];
  currentUser = null;
  constructor(private navigatorService: NavigatorService,
              private userService: UserService,
              private alertService: BootstrapNotifyService,
              private utilService: UtilService) {
    this.getProducts();
    this.getCategories();
  }

  ngOnInit() {
    this.currentUser =  this.utilService.getAuthUser();
  }
  getProducts() {
    this.loading = true;
    this.userService.getLatestArrival()
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.latestArrivals = res.data.slice(0, 12);
        // this.latestArrivals = this.Products.slice(0, 12);
        console.log('latestArrivals ', this.latestArrivals);
        this.loading = false;
      }, error => {
        console.log('Error ', error);
        this.loading = false;
      });
  }
  addToWishlist(product) {
    this.utilService.addToWishlist(product);
  }
  addToCart(product) {
    this.utilService.addToCart(product);
  }
  getCategories() {
    this.userService.getCategories().subscribe((res: IResponse) => {
      this.categories = res.data;
    }, error => {
      console.log({error});
    });
  }
  viewAll(url) {
    this.navigatorService.navigateUrl(url);
  }

  getProductsByCategory(category) {
    this.loading = true;
    this.userService.getLatestArrivalByCategory(category)
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.latestArrivals = res.data;
        console.log('latestArrivals ', this.latestArrivals);
        this.loading = false;
      }, error => {
        console.log('Error ', error);
        this.alertService.error(error.error.message);
        this.loading = false;
      });
  }/*
  filterByCategory() {
    const category = $('#categorySort').val();
    this.getProductsByCategory(category);
  }*/
  filterByCategory() {
    const category = $('#categorySort').val();
    if (category === 'menu_order') {
      this.getProducts();
    } else {
      this.getProductsByCategory(category);
    }
  }
}
