import { Component, OnInit } from '@angular/core';
import {UtilService} from '../../services/utilService/util.service';
import {IResponse} from '../../interfaces/iresponse';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {UserService} from '../../services/apiCalls/userService/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  breadCrumb: any;
  currentUser = null;
  loaders = {loading: false};
  user = {
    lastname: null,
    firstname: null,
    telephonenumber: null,
    country_id: 156,
    zone_id: 2412,
    address: null,
    city: null,
    postcode: 23401,
    username: null,
    email: null,
    accept_term: false
  };
  allCountries = [];
  allZones = [];
  changeCountry = false;
  constructor(private utilService: UtilService,
              private userService: UserService,
              private alertService: BootstrapNotifyService) {
    this.getCountries();
    this.getZones();

  }

  ngOnInit() {
    this.currentUser = this.utilService.getAuthUser();
    this.breadCrumb  = {
      name: 'My Account',
      parent: 'Home',
      subLink: null
    };
    this.getUser();
  }
  getUser() {
    this.userService.getUser(this.currentUser.customer_id).subscribe((res: IResponse) => {
      this.user = res.data || this.currentUser;
    }, err => {
      this.user = this.currentUser;
      console.log({err});
    });
  }
  public updateCustomer() {
    this.loaders.loading = true;
    if (!this.user.telephonenumber) {
      this.alertService.info('Phone Number is required');
      this.loaders.loading = false;
    } else if (!this.user.firstname) {
      this.alertService.info('First Name is required');
      this.loaders.loading = false;
    } else if (!this.user.lastname) {
      this.alertService.info('Last Name is required');
      this.loaders.loading = false;
    } else if (!this.user.username) {
      this.alertService.info('Username is required');
      this.loaders.loading = false;
    } else if (!this.user.country_id) {
      this.alertService.info('Select country!');
      this.loaders.loading = false;
    } else if (!this.user.zone_id) {
      this.alertService.info('Select your zone!');
      this.loaders.loading = false;
    } else {
      this.userService.updateCustomer(this.user).subscribe((response: IResponse) => {
        console.log('Response', response);
        this.loaders.loading = false;
        this.getUser();
        this.alertService.success('Profile updated successful');
      }, error => {
        this.loaders.loading = false;
        this.alertService.advance(error.error.message || 'Unable to update profile', 'right');
        console.info('Error => ', error);
      });
    }
  }

  public getCountries() {
    this.userService.getCountries().subscribe(res => {
      console.log('Resp ', res);
      this.allCountries = res.data;
    }, error => {
      console.log('Error ', error);
      this.alertService.error('Unable to list countries');
    });
  }

  public getZones(countryId = this.user.country_id) {
    this.changeCountry = false;
    this.userService.getCountryZones(countryId).subscribe(res => {
      console.log('Resp ', res);
      this.allZones = res.data;
      this.changeCountry = true;
    }, error => {
      console.log('Error ', error);
    });
  }

}
