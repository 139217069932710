import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/apiCalls/userService/user.service';
import {UtilService} from '../../services/utilService/util.service';
import {IResponse} from '../../interfaces/iresponse';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {CacheService} from '../../services/cacheService/cache.service';
import {NavigatorService} from '../../services/navigatorService/navigator.service';

@Component({
  selector: 'app-checkout-shipping',
  templateUrl: './checkout-shipping.component.html',
  styleUrls: ['./checkout-shipping.component.css']
})
export class CheckoutShippingComponent implements OnInit {
  currentUser = null;
  selectedAddressInfo = null;
  cartItems = [];
  paymentType = 'doorDelivery';
  addressBooks = [];
  totalInCart: any = 0;
  totalOrder: any = 0;
  taxTotal: any = 0;
  loading = true;
  defaultAddress = null;
  loaders = {
    saving: false
  };
  editAddress = null;
  shipping = {
    customer_id: null,
    title: 'Home',
    firstname: null,
    lastname: null,
    address: null,
    // country: 156,
    city: null,
    status: 1,
    // zone: 2412,
    phonenumber: null,
    shippingarea_id: 1
  };
  countries = [];
  zones = [];
  shippingAreas = [];
  shippingMethods = [];
  storageInfo = {selectedAddressInfo: null, paymentType: null, customer_id: null, paymentMode: 'online'};
  constructor(private userService: UserService, private utilService: UtilService,
              private cacheService: CacheService,
              private navigatorService: NavigatorService,
              private alertService: BootstrapNotifyService) {
   this.storageInfo =  JSON.parse(this.cacheService.getStorage('DELIVERY_DATA'));
  }

  ngOnInit() {
    this.currentUser =  this.utilService.getAuthUser();
    this.selectedAddressInfo = this.storageInfo && this.storageInfo.selectedAddressInfo;
    if (!this.selectedAddressInfo || this.currentUser.customer_id !== this.storageInfo.customer_id) {
      this.getAddressBooks();
    }
    // this.getCountry();
    this.getShippingArea();
    this.getCartItems();
    this.getShippingMethod();
  }

  getShippingMethod() {
    this.userService.getShippingMethods().subscribe((res: IResponse) => {
      console.log('shippingMethods ', res);
      this.shippingMethods = res.data;
    }, error => {
      console.log({error});
    });
  }
  getCountry() {
    this.userService.getCountries()
      .subscribe((res: IResponse) => {
      this.countries = res.data || [];
      console.log({res});
      this.getZones(156);
      }, error => {
      console.log({error});
      });
  }
  getZones(id) {
    this.userService.getCountryZones(id)
      .subscribe((res: IResponse) => {
      this.zones = res.data || [];
      console.log({res});
      }, error => {
      console.log({error});
      });
  }
  getCartItems() {
    this.loading = true;
    this.totalInCart = this.totalOrder = this.taxTotal = 0;
    this.userService.getCartItems({customer_id: this.currentUser.customer_id})
      .subscribe((res: IResponse) => {
        console.log('Res Cart ', res);
        this.cartItems = res.data;
        if (this.cartItems.length === 0 ) {
          this.alertService.error('You can\'t view page because, no items in cart');
          this.navigatorService.navigateUrl('/');
          return;
        } else {
          this.loading = false;
          this.cartItems.forEach((cart, i) => {
            // cart.total_ = parseFloat(cart.price) * parseInt(cart.quantity, 10);
            this.totalInCart += parseFloat(cart.total || 0 as any);
            this.taxTotal += parseFloat(cart.tax || 0);
          });
          // this.totalOrder = this.totalInCart + this.taxTotal + ;
          this.totalOrder = this.totalInCart + parseFloat(this.taxTotal) + parseFloat(this.selectedAddressInfo && this.selectedAddressInfo.amount || 0);

          // console.log('CART SUMMATION ', this.totalOrder, this.taxTotal, this.totalInCart, this.selectedAddressInfo.amount);
        }
      }, error => {
        console.log('Cart Error ', error);
        this.loading = false;
      });
  }
  getAddressBooks(type=null) {
    this.addressBooks = [];
    this.userService.getAddressBooks(this.currentUser.customer_id)
      .subscribe((res: IResponse) => {
        console.log('Res Books Address ', res);
        this.addressBooks = res.data || [];
        if(type !== 1) {
          this.selectedAddressInfo = this.addressBooks[0];
        }
      }, error => {      });
  }
  proceedToCheck() {
    console.log('Hey');
    if (!this.addressBooks.length) {
      return this.alertService.error('Please set shipping address to proceed!');
    }
  }
  openAddressModal(id) {
    this.getAddressBooks(1);
    this.utilService.openModal(id);
  }
  resetShipping() {
    this.shipping = {
      customer_id: null,
      title: 'Home',
      firstname: null,
      lastname: null,
      address: null,
      // country: 156,
      city: null,
      status: 1,
      // zone: 2412,
      phonenumber: null,
      shippingarea_id: 1
    };
  }
  createShippingAddress() {
    console.log('Create address ', this.shipping);
    if (!this.shipping.phonenumber) {
      return this.alertService.error('Mobile phone address is required!');
    } else if (!this.shipping.firstname) {
      return this.alertService.error('First name is required!');
    } else if (!this.shipping.lastname) {
      return this.alertService.error('Last name is required!');
    } else if (!this.shipping.address) {
      return this.alertService.error('Address is required!');
    } else if (this.editAddress) {
      this.loaders.saving = true;
      this.updateAddress();
    } else {
      this.shipping.customer_id = this.currentUser.customer_id;
      this.loaders.saving = true;
      this.userService.createAddress(this.shipping).subscribe((res: IResponse) => {
        console.log({res});
        this.loaders.saving = false;
        this.alertService.success('Shipping address added successfully');
        this.resetShipping();
        this.getAddressBooks();
        this.utilService.closeModal('addressBooks');
      }, error => {
        console.log({error});
        this.loaders.saving = false;
        this.alertService.error(error.error.message || 'Unable to add shipping address');
      });

    }
  }
  updateAddress() {
    this.shipping.customer_id = this.currentUser.customer_id;
    this.userService.updateAddress(this.shipping).subscribe((res: IResponse) => {
      console.log({res});
      this.loaders.saving = false;
      this.alertService.success('Shipping address updated successfully');
      this.resetShipping();
      this.getAddressBooks();
      this.editAddress = null;
      this.utilService.closeModal('addressBooks');
    }, error => {
      console.log({error});
      this.loaders.saving = false;
      this.alertService.error(error.error.message || 'Unable to update shipping address');
    });
  }
  getShippingArea() {
    this.userService.getShippingAreas().subscribe((res: IResponse) => {
      console.log('res ', res);
      this.shippingAreas = res.data;
    }, error => {
      console.log({error});
    });
  }
  editShippingAddress(address) {
    this.resetShipping();
    this.editAddress = this.shipping = JSON.parse(JSON.stringify(address));
    this.utilService.openModal('addressBooks');
    this.utilService.closeModal('listAddressBooks');
  }
  deleteShippingAddress(address) {
    console.log({address});
    this.utilService.confirmAction(() => {
      this.userService.deleteShippingAddress(address.id).subscribe((res: IResponse) => {
        this.alertService.success(res.message || 'Shipping address deleted successfully');
      }, error => {
       console.log({error});
       this.alertService.error(error.error.message);
      });
    });
  }
  useThisAddress() {
    const pos: any = $('input[name=\'shipping-address-\']:checked').val() || 0;
    console.log('this.defaultAddress ', pos);
    this.selectedAddressInfo = this.addressBooks[parseInt(pos, 10)];
    this.utilService.closeModal('listAddressBooks');
  }
  checkAndReview() {
    // selectedAddressInfo
    //
  if (!this.paymentType) {
      return this.alertService.error('Please select a delivery method!');
    } else if (this.paymentType === 'pickupStation' && !this.selectedAddressInfo) {
    return this.alertService.error('Please select a shipping address or create new one!');
   } else {
     const delivery = {
       paymentType: this.paymentType,
       selectedAddressInfo: this.selectedAddressInfo,
       customer_id: this.currentUser.customer_id,
       paymentMode: 'online'
     };
     this.cacheService.setStorage('DELIVERY_DATA', JSON.stringify(delivery));
     this.navigatorService.navigateUrl('/checkout-review');
   }
  }
}
