import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  constructor() { }
  post(obj, url) {
    const mapForm = document.createElement('form');
    mapForm.target = '_blank';
    mapForm.method = 'POST'; // or "post" if appropriate
    mapForm.action = url;
    Object.keys(obj).forEach(function(param) {
      const mapInput = document.createElement('input');
      mapInput.type = 'hidden';
      mapInput.name = param.trim();
      mapInput.setAttribute('value', obj[param]);
      mapForm.appendChild(mapInput);
    });
    document.body.appendChild(mapForm);
    console.log('MAPP ', mapForm);
    mapForm.submit();
  }
}
