import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {UtilService} from '../../../../services/utilService/util.service';
import {UserService} from '../../../../services/apiCalls/userService/user.service';
import {IResponse} from '../../../../interfaces/iresponse';
import {BootstrapNotifyService} from '../../../../services/bootstrap-notify/bootstrap-notify.service';
import {EventsService} from '../../../../services/eventServices/event.service';
import {AuthService} from '../../../../services/authService/auth.service';
import {Router} from "@angular/router";
import {CacheService} from "../../../../services/cacheService/cache.service";
import {NavigatorService} from "../../../../services/navigatorService/navigator.service";


@Component({
  selector: 'app-l-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
@Input() active: string;
@Input() buttonLess: string;
public currentUser = null;
  loading = false;
cartItems = [];
  cartItems_ = [];
  categories_with_no_sub = [];
  categories = [];
  categories_ = [];
totalInCart = 0.0;
  search = {
    category_id: undefined,
    title: null
  };
  searching = false;
cartPath = window.location.origin + '/my-cart';
  constructor(private utilService: UtilService, private userService: UserService,
              private eventService: EventsService,
              private authService: AuthService,
              private navigatorService: NavigatorService,
              private router: Router,
              private cacheService: CacheService,
              private alertService: BootstrapNotifyService) {
    this.currentUser =  this.utilService.getAuthUser();
    this.getCategories();
    this.eventService.on('ADDEDTOCART', () => {
      this.alertService.success('Item added to cart!');
      if (this.currentUser) {
        this.getCartItems();
      } else {
        this.getOfflineCart();
      }
    });
    this.eventService.on('GET_CARTS', () => {
      this.alertService.success('Get new carts!');
      if (this.currentUser) {
        this.getCartItems();
      } else {
        this.getOfflineCart();
      }
    });
  }

  ngOnInit() {
   console.log('Userr 2', this.currentUser);
    if (this.currentUser) {
      this.getCartItems();
    } else {
      this.getOfflineCart();
    }
  }
  ngAfterViewInit() {
    console.log('SCROLS');
    window.scrollTo(0, 0);
    $('html, body').animate({
      scrollTop: 0
    }, 600);
    // $(window).scrollTop(0);
    this.utilService.showModal();
  }
  dontShow() {
    const val = $('#dontShowValue').val();
     sessionStorage.setItem('newLetterPopup', JSON.stringify(!!val));
  }
  gotoPage(path) {
    // e.preventDefault();
    // console.log('EVEnt ', e);
    window.location.assign(window.location.origin + path);
  }

  getCartItems() {
    this.loading = true;
    this.userService.getCartItems({customer_id: this.currentUser.customer_id})
      .subscribe((res: IResponse) => {
      console.log('Res Cart ', res);
      this.cartItems = res.data;
      this.totalInCart = 0;
      this.cartItems_ = this.cartItems.slice(0, 3);
        this.loading = false;
        this.cartItems.forEach((cart, i) => {
        this.totalInCart += parseFloat(cart.total);
      });
    }, error => {
      console.log('Cart Error ', error);
        this.loading = false;
      });
  }
  getOfflineCart() {
    const oldCart = JSON.parse(this.cacheService.getSession('CART')) || [];
    this.totalInCart = 0;
    this.cartItems_ = oldCart.slice(0, 3);
    oldCart.forEach((cart, i) => {
      this.totalInCart += (parseFloat(cart.price) * parseInt(cart.quantity, 10));
    });
    this.cartItems = oldCart;
    console.log('CART FROM CACHE', oldCart, this.totalInCart)
    this.loading = false;
  }

  prepareCartAndLogin() {
    this.alertService.info('Sign up or login to checkout your cart!');
    this.navigatorService.navigateUrl('/login');
  }
  getCategories() {
    this.categories = [];
    this.userService.getMegaCategories()
      .subscribe((res: IResponse) => {
        console.log('Res Category ', res);
        this.categories = res.data;
        // const categories = this.categories.filter(cat => cat.subcategory.length);
        const categories_ = this.categories.filter(cat => {

          return (cat.category.category_name.toLowerCase() === 'bibles' ||
          cat.category.category_name.toLowerCase() === 'business books' ||
          cat.category.category_name.toLowerCase() === 'christian books' ||
          cat.category.category_name.toLowerCase() === 'fragrances' ||
          cat.category.category_name.toLowerCase().includes('children') );
        });
        this.categories_with_no_sub  = categories_.slice(0, 5);
      }, error => {
        this.categories = [];
      });
  }
  removeFromCart(cart, index=null) {
    console.log('Cart ', cart);
    if(!this.currentUser) {
      const oldCart = JSON.parse(this.cacheService.getSession('CART')) || [];
      oldCart.splice(index, 1);
      this.cacheService.setSession('CART', JSON.stringify(oldCart) || []);
      this.getOfflineCart();
    } else {
      this.userService.removeCart({customer_id: this.currentUser.customer_id,
        cart_id: cart.cart_id, product_id: cart.product_id}).subscribe((res: IResponse) => {
        this.getCartItems();
      }, error => {
        this.alertService.error(error.error.message || 'Unable to remove product');
      });
    }
  }
  logout() {
    this.authService.logUserOut();
  }
  searchProduct() {
    console.log('SEARCH ', this.search, this.router.url);
    // this.searching = true;
    if (this.router.url.includes('/search-filter-products?')) {
      this.eventService.broadcast('SEARCH_PRODUCT', { category_id: this.search.category_id, search: this.search.title });
    } else {
      this.router.navigate(['/search-filter-products'], { queryParams: { category_id: this.search.category_id, title: this.search.title }});
    }
  }
}

