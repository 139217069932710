import { Component, OnInit } from '@angular/core';
import {UtilService} from '../../services/utilService/util.service';
import {IResponse} from '../../interfaces/iresponse';
import {UserService} from '../../services/apiCalls/userService/user.service';
import {EventsService} from '../../services/eventServices/event.service';
import {ActivatedRoute} from '@angular/router';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {NavigatorService} from '../../services/navigatorService/navigator.service';
import {CacheService} from '../../services/cacheService/cache.service';
import {RedirectService} from "../../services/redirectService/redirect.service";
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.css']
})
export class VoucherComponent implements OnInit {
  breadCrumb = {
    name: 'Voucher',
    parent: 'Home',
    subLink: null
  };
  currentUser = null;
  voucherHistory = [];
  voucherDonominations = [];
  paymentChannels = [];
  loading = false;
  transaction_reference = null;

  selectedPaymentGateway = null;
  selectedDenomination = null;
  public buyVoucher = {
    payment_mode: null,
    voucherprice_id: null,
    url: null
  };

  transferVoucher = {
    email: null,
    voucher_id: null
  };
  public loaders = {
    loading: false,
    saving: false
  };

  constructor(private utilService: UtilService,
              private route: ActivatedRoute,
              private navigateService: NavigatorService,
              private eventService: EventsService,
              private cacheService: CacheService,
              private alertService: BootstrapNotifyService,
              private redirect: RedirectService,
              private userService: UserService) {
    const query: any = this.route.snapshot.queryParams;
    this.transaction_reference = query.trxref || query.reference;
    // this.paymentWebPayId = this.route.snapshot.queryParamMap.get('txn_ref') || null;

  }

  ngOnInit() {
    this.currentUser = this.utilService.getAuthUser();
    console.info('Current User', this.currentUser);
    this.getVoucherHistory();
    this.getVoucherDenominations();
    this.getPaymentChannels();
    if (this.transaction_reference) {
      this.alertService.success('Redirect from payment gateways');
      this.verifyingPayment(this.transaction_reference);
    }
  }

  getVoucherHistory() {
    this.loading = true;
    this.voucherHistory = [];
    this.userService.getVoucherHistory()
      .subscribe((res: IResponse) => {
        console.log('Voucher History ', res);
        this.voucherHistory = res.data || [];
        this.loading = false;
      }, error => {
        this.loading = false;
      });
  }
  public triggerBuyVoucher() {
    this.utilService.openModal('buyVoucher');
  }
  public verifyingPayment(transaction_reference = null) {
    this.alertService.info('Verifying voucher purchase, please wait!');
    this.userService.voucherVerifyFunding(transaction_reference)
      .subscribe((res: IResponse) => {
        console.log('Res ', res);
        this.alertService.success(res.message || 'Transaction successfully verified');
        this.cacheService.deleteStorage('TRANSACTION_REFERENCE');
        this.getVoucherHistory();
      }, err => {
        console.log('Err ', err);
        const refCode = this.cacheService.getStorage('TRANSACTION_REFERENCE');
        if (refCode) {
          this.verifyingPayment(refCode);
          this.cacheService.deleteStorage('TRANSACTION_REFERENCE');
        } else {
          this.getVoucherHistory();
          this.alertService.error(err.error.message || 'Unable to verify transaction with that code');
        }
      });
  }
  getVoucherDenominations() {
    this.userService.listVoucherDenomination()
      .subscribe((res: IResponse) => {
        this.voucherDonominations = res.data;
        this.voucherDonominations.forEach((denomination) => {
          denomination.name_price = `${denomination.name} - NGN ${denomination.amount}`;
        });
        console.log('RES voucher ', res);
        this.alertService.success(res.message || 'Voucher denominations listed successfully!');
      }, error => {
        console.log({error});
        this.alertService.error(error.error.message || 'Unable to list voucher denominations!');
      });
  }







  public getPaymentChannels() {
    this.userService.listPaymentModes().subscribe((res: IResponse) => {
      this.paymentChannels = res.data.filter(d => d.name.toLowerCase() !== 'wallet' && d.name.toLowerCase() !== 'cash on delivery');
      console.log('PAYMENT CHANNELS ', this.paymentChannels);
      // this.paymentChannels = this.paymentChannels.filter(channel => channel.is_active === true && channel.is_editable);
    }, error => {
      console.log('Unable to get payment Channels');
    });
  }






  public proceedToFund() {
    if (!this.buyVoucher.payment_mode) {
      return this.alertService.warning('Select payment mode for this transaction!');
    } else if (!this.buyVoucher.voucherprice_id) {
      return this.alertService.warning('Select voucher denomination');
    } else {
      this.loaders.saving = true;
      console.log('URL ', window.location);
      this.buyVoucher.url = `${window.location.origin}/user/voucher`;
      this.userService.buyVoucherInit(this.buyVoucher).subscribe((res: IResponse) => {
        this.loaders.saving = false;
        console.log('Buy Voucher ', res);
        this.utilService.closeModal('buyVoucher');
        this.alertService.success(res.message || 'Voucher purchase initiated successfully');
        this.proceedToGateway(res.data);
        // this.eventService.broadcast('RELOAD_HISTORY');
      }, error => {
        this.loaders.saving = false;
        this.alertService.error(error.error.message || 'Unable to purchase voucher at this moment, please try again later!', 'right');
      });
    }
  }
  triggerVoucherTransfer(voucher) {
    this.transferVoucher.voucher_id = voucher.voucher_id || 0;
    this.utilService.openModal('transferVoucher');
  }
  transferVoucherNow() {
    if(!this.transferVoucher.voucher_id) {
      return this.alertService.info('Voucher ID is required!');
    } else if(!this.transferVoucher.email) {
      return this.alertService.error('Recipient email is required');
    } else if(!this.transferVoucher.email.match(environment.EMAIL_VALIDATION)) {
      return this.alertService.error('Recipient email is not a valid email address');
    } else {
      this.loaders.saving = true;
      this.userService.transferVoucher(this.transferVoucher).subscribe((res: IResponse) => {
        this.loaders.saving = false;
        console.log('Transfer Voucher ', res);
        this.utilService.closeModal('transferVoucher');
        this.alertService.success(res.message || 'Voucher transferred successfully');
        this.getVoucherHistory();
      }, error => {
        this.loaders.saving = false;
        this.alertService.error(error.error.message || 'Unable to transfer voucher, please try again!', 'right');
      });
    }

  }
  updateChannel(e) {
    if (!e) {
      return false;
    }
    this.selectedPaymentGateway = e;
    console.log('SELECTED GATE ', this.selectedPaymentGateway);
  }
  updateDenomination(e) {
    if (!e) {
      return false;
    }
    this.selectedDenomination = e;
    console.log('SELECTED Denomination ', this.selectedDenomination);
  }
  public proceedToGateway(data) {
    if (this.selectedPaymentGateway.name.toLowerCase().includes('paystack')) {
      this.cacheService.setStorage('TRANSACTION_REFERENCE', data.reference);
      window.location.assign(data.authorization_url);
    } else if (this.selectedPaymentGateway.name.toLowerCase().includes('webpay')) {
      console.log('RESc ', data);
      this.cacheService.setStorage('TRANSACTION_REFERENCE', data.fields.txn_ref);
      this.redirect.post(data.fields, data.payment_url);
    } else {
      this.alertService.info('Unable to redirect to payment gateway');
    }
  }
  toggleVisibility(voucher, id){
    $('#view' + id).removeClass('d-none');
    $('#no_view' + id).addClass('d-none');
    setTimeout(() => {
      $('#no_view' + id).removeClass('d-none');
      $('#view' + id).addClass('d-none');
    }, 15000);
  }
}
