import {IRouting} from './interfaces/irouting';
import {RouterModule, Routes} from '@angular/router';
import {UserRoutesComponent} from './users/user-routes/user-routes.component';
import {LoginComponent} from './landing/login/login.component';
import {GuardService, RoleService} from './services/gaurdService/guard.service';
import {SuperUserRoutesComponent} from './admin-user/admin-user-routes/admin-user-routes.component';
import {AdminDashboardComponent} from './admin-user/admin-dashboard/admin-dashboard.component';
import {ResetPasswordComponent} from './landing/reset-password/reset-password.component';
import {PageNotFoundComponent} from './shared/components/page-not-found/page-not-found.component';
import {SetPasswordComponent} from './landing/set-password/set-password.component';
import {RegisterComponent} from './landing/register/register.component';
import {SuperAdminService} from './services/api-handlers/superAdminService/super-admin.service';
import {UserDashboardComponent} from './users/user-dashboard/user-dashboard.component';
import {HomeComponent} from './landing/home/home.component';
import {FooterComponent} from './shared/layout/landing/footer/footer.component';
import {HeaderComponent} from './shared/layout/landing/header/header.component';
import {DeleteService} from './services/firebase-delete/delete.service';
import {ProfileComponent} from './users/profile/profile.component';
import {BlogComponent} from './landing/blog/blog.component';
import {ContactUsComponent} from './landing/contact-us/contact-us.component';
import {TermsComponent} from './landing/terms/terms.component';
import {CareerComponent} from './landing/career/career.component';
import {HowToShopComponent} from './landing/how-to-shop/how-to-shop.component';
import {NavBarComponent} from './shared/layout/landing/nav-bar/nav-bar.component';
import {AboutComponent} from './landing/about/about.component';
import {PrivacyComponent} from './landing/privacy/privacy.component';
import {ViewProductDetailsComponent} from './landing/view-product-details/view-product-details.component';
import {ViewCartComponent} from './landing/view-cart/view-cart.component';
import {CheckoutShippingComponent} from './landing/checkout-shipping/checkout-shipping.component';
import {CheckoutReviewComponent} from './landing/checkout-review/checkout-review.component';
import {WishlistProductsComponent} from './shared/components/wishlist-products/wishlist-products.component';
import {AllProductsComponent} from './landing/all-products/all-products.component';
import {MyWishlistComponent} from './users/my-wishlist/my-wishlist.component';
import {MyAddressbooksComponent} from './users/my-addressbooks/my-addressbooks.component';
import {MyOrdersComponent} from './users/my-orders/my-orders.component';
import {LatestArrivalComponent} from './shared/components/latest-arrival/latest-arrival.component';
import {WalletComponent} from './users/wallet/wallet.component';
import {BestSellersComponent} from './landing/best-sellers/best-sellers.component';
import {LatestArrivalsComponent} from './landing/latest-arrivals/latest-arrivals.component';
import {SimilarProductsComponent} from "./shared/components/similar-products/similar-products.component";
import {FaqComponent} from "./landing/faq/faq.component";
import {ReturnPolicyComponent} from "./landing/return-policy/return-policy.component";
import {VoucherComponent} from "./users/voucher/voucher.component";
import {SocialAuthComponent} from "./shared/components/social-auth/social-auth.component";

const landingRoutes: Routes = [
  {path: '', component: HomeComponent, pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'products/view-detail/:productId/:productName', component: ViewProductDetailsComponent},
  {path: 'products/view-detail/:productId/:productName/:product_name', component: ViewProductDetailsComponent},
  // data: {roles: ['customer']}, canActivate: [GuardService, RoleService]
  {path: 'my-cart', component: ViewCartComponent},
  {path: 'checkout', component: CheckoutShippingComponent, data: {roles: ['customer']}, canActivate: [GuardService, RoleService]},
  {path: 'best-seller', component: BestSellersComponent},
  {path: 'latest-arrival', component: LatestArrivalsComponent},
  {path: 'checkout-review', component: CheckoutReviewComponent, data: {roles: ['customer']}, canActivate: [GuardService, RoleService]},
  {path: 'checkout-review/:paymentId', component: CheckoutReviewComponent, data: {roles: ['customer']}, canActivate: [GuardService, RoleService]},
  {path: 'blog', component: BlogComponent},
  {path: 'search-filter-products', component: AllProductsComponent},
  {path: 'products', component: AllProductsComponent},
  {path: 'career', component: CareerComponent},
  {path: 'how-to-shop', component: HowToShopComponent},
  {path: 'contact-us', component: ContactUsComponent},
  {path: 'about-us', component: AboutComponent},
  {path: 'terms-and-conditions', component: TermsComponent},
  {path: 'privacy-policy', component: PrivacyComponent},
  {path: 'return-policy', component: ReturnPolicyComponent},
  {path: 'faq', component: FaqComponent},
  {path: 'login', component: LoginComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'forgot-password', component: ResetPasswordComponent},
  {path: 'reset-password/token/:token', component: SetPasswordComponent},

  {path: 'user', loadChildren: './shared/modules/user/user.module#UserModule'},
  // {path: 'super', loadChildren: './shared/modules/super-user/super-user.module#SuperUserModule'},

  {path: '**', component: HomeComponent},
];

export const landingRouting: IRouting = {
  routes: RouterModule.forRoot(landingRoutes, {useHash: false}),
  components: [
    LoginComponent,
    ResetPasswordComponent,
    RegisterComponent,
    SetPasswordComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    LatestArrivalComponent,
    PrivacyComponent,
    BlogComponent,
    ContactUsComponent,
    TermsComponent,
    HowToShopComponent,
    CareerComponent,
    AboutComponent,
    ViewProductDetailsComponent,
    SimilarProductsComponent,
    ViewCartComponent,
    AllProductsComponent,
    FaqComponent,
    ReturnPolicyComponent,

    CheckoutShippingComponent,
    CheckoutReviewComponent,
    WishlistProductsComponent,
    BestSellersComponent,
    LatestArrivalsComponent,
    SocialAuthComponent

  ],
  entryComponent: [],
  providers: []
};

// , canActivate: [GuardService]
export const userRoutes: Routes = [
  {
    path: '', component: UserRoutesComponent,
    children: [
      // data: {roles: ['user']}, canActivate: [GuardService, RoleService2]
      {path: 'dashboard', component: UserDashboardComponent, data: {roles: ['customer']}, canActivate: [GuardService, RoleService]},
      {path: 'my-profile', component: ProfileComponent, data: {roles: ['customer']}, canActivate: [GuardService, RoleService]},
      {path: 'my-wishlist', component: MyWishlistComponent, data: {roles: ['customer']}, canActivate: [GuardService, RoleService]},
      {path: 'my-orders', component: MyOrdersComponent, data: {roles: ['customer']}, canActivate: [GuardService, RoleService]},
      {path: 'address-book', component: MyAddressbooksComponent, data: {roles: ['customer']}, canActivate: [GuardService, RoleService]},
      {path: 'wallet', component: WalletComponent, data: {roles: ['customer']}, canActivate: [GuardService, RoleService]},
      {path: 'voucher', component: VoucherComponent, data: {roles: ['customer']}, canActivate: [GuardService, RoleService]},
    ]
  },
  {path: '**', component: UserRoutesComponent , redirectTo: 'dashboard'}
];


export const userRouting: IRouting = {
  routes: RouterModule.forChild(userRoutes),
  components: [
    UserRoutesComponent,
    UserDashboardComponent,
    ProfileComponent,
    MyWishlistComponent,
    MyAddressbooksComponent,
    MyOrdersComponent,
    WalletComponent,
    VoucherComponent,
  ],
  entryComponent: [],
  providers: []
};
export const superUserRoutes: Routes = [
  {path: '', component: SuperUserRoutesComponent, children: [
      {path: 'dashboard', component: AdminDashboardComponent, data: {roles: ['super']}, canActivate: [GuardService, RoleService]}

    ]},
  {path: '**', component: SuperUserRoutesComponent , redirectTo: 'dashboard'}
];

export const superUserRouting: IRouting = {
  routes: RouterModule.forChild(superUserRoutes),
  components: [
    SuperUserRoutesComponent,
    AdminDashboardComponent,
  ],
  entryComponent: [],
  providers: [SuperAdminService, DeleteService, RoleService]
};

