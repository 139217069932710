import { Component, OnInit } from '@angular/core';
import {UserService} from "../../services/apiCalls/userService/user.service";
import {IResponse} from "../../interfaces/iresponse";
import {UtilService} from "../../services/utilService/util.service";
import {BootstrapNotifyService} from "../../services/bootstrap-notify/bootstrap-notify.service";

@Component({
  selector: 'app-latest-arrivals',
  templateUrl: './latest-arrivals.component.html',
  styleUrls: ['./latest-arrivals.component.css']
})
export class LatestArrivalsComponent implements OnInit {
  categories = [];
  latestArrivals = [];
  loading = false;
  showPaginator = true;

  limit = 18;
  page = 1;
  start_output = 1;
  end_output = 18;
  total_results = 20;

  action = '+';


  constructor(private userService: UserService,
              private alertService: BootstrapNotifyService,
              private utilService: UtilService) {
    this.getCategories();
  }

  ngOnInit() {
    this.utilService.setPageTitle('Latest Arrival');
    this.getProducts();
  }

  getProducts() {
    this.loading = true;
    this.userService.getLatestArrivalPage(this.limit, this.page)
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.latestArrivals = res.data;

        this.showPaginator = true;

        this.total_results = res.total_number || this.limit;
        if(this.latestArrivals.length) {
          this.start_output = (( this.limit * this.page ) - this.limit) + 1;
          if(this.page === 1) {
            this.end_output = this.latestArrivals.length;
          } else {
            if(this.action === '-'){
              this.end_output -=this.latestArrivals.length;
            } else {
              this.end_output +=this.latestArrivals.length;
            }
          }
        }


        console.log('featuredProducts ', this.latestArrivals);
        this.loading = false;
        this.scrollTop();
      }, error => {
        console.log('Error ', error);
        this.loading = false;
      });
  }
  public scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  addToWishlist(product) {
    this.utilService.addToWishlist(product);
  }
  addToCart(product) {
    this.utilService.addToCart(product);
  }
  getCategories() {
    this.userService.getCategories().subscribe((res: IResponse) => {
      this.categories = res.data;
    }, error => {
      console.log({error});
    });
  }
  getProductsByCategory(category) {
    this.loading = true;
    this.userService.getLatestArrivalByCategory(category)
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.latestArrivals = res.data;
        this.showPaginator = false;
        console.log('featuredProducts ', this.latestArrivals);
        this.loading = false;
        this.scrollTop();
      }, error => {
        console.log('Error ', error);
        this.alertService.error(error.error.message);
        this.loading = false;
      });
  }
  filterByCategory() {
    const category = $('#categorySort').val();
    if (category === 'menu_order') {
      this.getProducts();
    } else {
      this.getProductsByCategory(category);
    }
  }

  getPrevious() {
    if(this.page === 1) {
      return false;
    }
    this.action = '-';
    this.page -= 1;
    this.scrollTop();
    this.getProducts();
  }
  getNext() {
    if(!this.latestArrivals.length) {
      return false;
    }
    this.action = '+';
    this.page += 1;
    this.scrollTop();
    this.getProducts();
  }
}
