import { Component, OnInit } from '@angular/core';
import {NavigatorService} from '../../../services/navigatorService/navigator.service';
import {UserService} from '../../../services/apiCalls/userService/user.service';
import {BootstrapNotifyService} from '../../../services/bootstrap-notify/bootstrap-notify.service';
import {UtilService} from '../../../services/utilService/util.service';
import {IResponse} from '../../../interfaces/iresponse';

@Component({
  selector: 'app-wishlist-products',
  templateUrl: './wishlist-products.component.html',
  styleUrls: ['./wishlist-products.component.css']
})
export class WishlistProductsComponent implements OnInit {
  loading = true;
  wishListProducts: any[] = [];
  Products: any[] = [];
  currentUser = null;
  constructor(private navigatorService: NavigatorService,
              private userService: UserService,
              private alertService: BootstrapNotifyService,
              private utilService: UtilService) {
  }

  ngOnInit() {
    this.currentUser =  this.utilService.getAuthUser();
    if(this.currentUser) {
      this.getWishLists();
    }
  }
  getWishLists() {
    this.loading = true;
    this.userService.getWishLists(this.currentUser.customer_id)
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        const wishListProducts = res.data;
        this.wishListProducts = wishListProducts.slice(0, 6);
        this.loading = false;
      }, error => {
        this.wishListProducts = [];
        console.log('Error ', error);
        this.loading = false;
      });
  }
  addToWishlist(product) {
    this.utilService.addToWishlist(product);
  }
  addToCart(product) {
    this.utilService.addToCart({product});
  }
  clearList() {
    this.loading = true;
    this.userService.emptyWishLists(this.currentUser.customer_id).subscribe((res: IResponse) => {
      this.loading = false;
      this.getWishLists();
      }, error => {
      this.loading = false;
    });
  }

}
