import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {UtilService} from '../../../../services/utilService/util.service';
import {UserService} from '../../../../services/apiCalls/userService/user.service';
import {IResponse} from '../../../../interfaces/iresponse';
import {BootstrapNotifyService} from '../../../../services/bootstrap-notify/bootstrap-notify.service';
import {EventsService} from '../../../../services/eventServices/event.service';
import {AuthService} from "../../../../services/authService/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Input() active: string;
  @Input() buttonLess: string;
  public currentUser = null;
  loading = false;
  cartItems = [];
  cartItems_ = [];
  categories_with_no_sub = [];
  categories = [];
  categories_ = [];
  totalInCart = 0.0;
  search = {
    category_id: undefined,
    title: null
  };
  searching = false;
  cartPath = window.location.origin + '/my-cart';
  constructor(private utilService: UtilService, private userService: UserService,
              private eventService: EventsService,
              private authService: AuthService,
              private router: Router,
              private alertService: BootstrapNotifyService) {
    this.getCategories();
    this.eventService.on('ADDEDTOCART', () => {
      this.alertService.success('Item added to cart!');
      this.getCartItems();
    });
  }

  ngOnInit() {
    this.currentUser =  this.utilService.getAuthUser();
    console.log('Userr ', this.currentUser);
    if (this.currentUser) {
      this.getCartItems();
    }
  }
  ngAfterViewInit() {
    console.log('SCROLS');
    window.scrollTo(0, 0);
    $('html, body').animate({
      scrollTop: 0
    }, 600);
    // $(window).scrollTop(0);
    this.utilService.showModal();
  }
  dontShow() {
    const val = $('#dontShowValue').val();
    sessionStorage.setItem('newLetterPopup', JSON.stringify(!!val));
  }
  gotoPage(path) {
    // e.preventDefault();
    // console.log('EVEnt ', e);
    window.location.assign(window.location.origin + path);
  }

  getCartItems() {
    this.loading = true;
    this.userService.getCartItems({customer_id: this.currentUser.customer_id})
      .subscribe((res: IResponse) => {
        console.log('Res Cart ', res);
        this.cartItems = res.data;
        this.totalInCart = 0;
        this.cartItems_ = this.cartItems.slice(0, 3);
        this.loading = false;
        this.cartItems.forEach((cart, i) => {
          this.totalInCart += parseFloat(cart.total);
        });
      }, error => {
        console.log('Cart Error ', error);
        this.loading = false;
      });
  }
  getCategories() {
    this.categories = [];
    this.userService.getMegaCategories()
      .subscribe((res: IResponse) => {
        console.log('Res Category ', res);
        this.categories = res.data;
        // const categories = this.categories.filter(cat => cat.subcategory.length);
        // const categories_ = this.categories.filter(cat => cat.subcategory.length === 0);
        // this.categories_  = categories.slice(0, 6);
        // this.categories_with_no_sub  = categories_.slice(0, 3);
      }, error => {
        this.categories = [];
      });
  }
  removeFromCart(cart) {
    console.log('Cart ', cart);
    this.userService.removeCart({customer_id: this.currentUser.customer_id,
      cart_id: cart.cart_id, product_id: cart.product_id}).subscribe((res: IResponse) => {
      this.getCartItems();
    }, error => {
      this.alertService.error(error.error.message || 'Unable to remove product');
    });
  }
  logout() {
    this.authService.logUserOut();
  }
  searchProduct() {
    console.log('SEARCH ', this.search, this.router.url);
    // this.searching = true;
    if (this.router.url.includes('/search-filter-products?')) {
      this.eventService.broadcast('SEARCH_PRODUCT', { category_id: this.search.category_id, search: this.search.title });
    } else {
      this.router.navigate(['/search-filter-products'], { queryParams: { category_id: this.search.category_id, title: this.search.title }});
    }
  }
}
