/**
 * Created by Arokoyu Olalekan Ojo
 */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {IResponse} from '../../../interfaces/iresponse';
import {ApiService} from '../../api/api.service.';
import {DecryptService} from '../../decryptService/decrypt.service';
@Injectable()
export class UserService {

  constructor(private api: ApiService,
              private decryptionService: DecryptService) {

  }



  auth(data): Observable<IResponse> {
    return this.api.postRequest('login', 'authenticate' , data).map((res: IResponse)  => {
      return res ;
    });
  }
  social_login(data): Observable<IResponse> {
    return this.api.postRequest('socialmedia', 'login', data).map((res: IResponse)  => {
      return res ;
    });
  }

  social_register(data): Observable<IResponse> {
    return this.api.postRequest('socialmedia', 'register', data).map((res: IResponse)  => {
      return res ;
    });
  }

  register(data): Observable<IResponse> {
    return this.api.postRequest('auth', 'register' , data).map((res: IResponse)  => {
      return res ;
    });
  }

  createCustomer(data): Observable<IResponse> {
    return this.api.postRequest('customer/account/create', 'authenticate' , data).map((res: IResponse)  => {
      return res ;
    });
  }
  updateCustomer(data): Observable<IResponse> {
    return this.api.putRequest('customer', 'profile/update' , data).map((res: IResponse)  => {
      return res ;
    });
  }
  createAddress(data): Observable<IResponse> {
    return this.api.postRequest('shippingaddress', 'create' , data).map((res: IResponse)  => {
      return res ;
    });
  }
  customerReview(data): Observable<IResponse> {
    return this.api.postRequest('customer/productreview', 'add' , data).map((res: IResponse)  => {
      return res ;
    });
  }
  getProductReviews(product_id): Observable<IResponse> {
    return this.api.getRequest('product', 'review/' + product_id).map((res: IResponse)  => {
      return res ;
    });
  }
  guestReview(data): Observable<IResponse> {
    return this.api.postRequest('guest/productreview', 'add' , data).map((res: IResponse)  => {
      return res ;
    });
  }
  notifyMe(data): Observable<IResponse> {
    return this.api.postRequest('notifyme', 'create' , data).map((res: IResponse)  => {
      return res ;
    });
  }
  createOrder(data): Observable<IResponse> {
    return this.api.postRequest('order', 'create' , data).map((res: IResponse)  => {
      return res ;
    });
  }
  createInvoice(data): Observable<IResponse> {
    return this.api.postRequest('invoice', 'create' , data).map((res: IResponse)  => {
      return res ;
    });
  }
  updateAddress(data): Observable<IResponse> {
    return this.api.putRequest('shippingaddress', 'update' , data).map((res: IResponse)  => {
      return res ;
    });
  }

  public getCartItems(data): Observable<IResponse> {
    return this.api.postRequest('cart/product', 'list', data).map( (res: IResponse) => {
      return res;
    });
  }
  public getMegaCategories(): Observable<IResponse> {
    return this.api.getRequest('megacategory', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public getCategories(): Observable<IResponse> {
    return this.api.getRequest('category', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public getSubCategories(category_id): Observable<IResponse> {
    return this.api.getRequest('subcategory', 'list/' + category_id).map( (res: IResponse) => {
      return res;
    });
  }
  public getAddressBooks(customer_id): Observable<IResponse> {
    return this.api.getRequest('shippingaddress/list', customer_id).map( (res: IResponse) => {
      return res;
    });
  }
  public getWalletHistory(): Observable<IResponse> {
    return this.api.getRequest('wallet', 'wallethistory').map( (res: IResponse) => {
      return res;
    });
  }
  public getVoucherHistory(): Observable<IResponse> {
    return this.api.getRequest('vouchers', null).map( (res: IResponse) => {
      return res;
    });
  }
  public removeCart(data): Observable<IResponse> {
    return this.api.postRequest('cart/product', 'remove', data).map( (res: IResponse) => {
      return res;
    });
  }
  public removeWishList(id): Observable<IResponse> {
    return this.api.deleteRequest('wishlist/remove', id).map( (res: IResponse) => {
      return res;
    });
  }
  public clearCart(id): Observable<IResponse> {
    return this.api.deleteRequest('cart/product', 'empty?customer_id=' + id).map( (res: IResponse) => {
      return res;
    });
  }
  public getProducts(limit, page): Observable<IResponse> {
    return this.api.getRequest('product', 'list?limit='+limit + '&&page='+page).map( (res: IResponse) => {
      return res;
    });
  }
  public getProductsByCategory(cat_id, limit, page): Observable<IResponse> {
    return this.api.getRequest('product/category', 'list/' + cat_id +'?limit='+ limit + '&&page='+ page).map( (res: IResponse) => {
      return res;
    });
  }
  public getAuthors(): Observable<IResponse> {
    return this.api.getRequest('manufacturer', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public searchProducts(option, limit, page): Observable<IResponse> {
    return this.api.postRequest('product', 'search?limit=' + limit + '&&page=' + page, option).map( (res: IResponse) => {
      return res;
    });
  }
  public filterProducts(option, limit, page): Observable<IResponse> {
    return this.api.postRequest('product', 'filter?limit=' + limit + '&&page=' + page, option).map( (res: IResponse) => {
      return res;
    });
  }
  public getBestSellersProducts(): Observable<IResponse> {
    return this.api.getRequest('bestsellerproduct', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public getBestSellerByCategory(id): Observable<IResponse> {
    return this.api.getRequest('categorybestsellerproduct', 'list/' + id).map( (res: IResponse) => {
      return res;
    });
  }
  public getLatestArrival(): Observable<IResponse> {
    return this.api.getRequest('newproduct', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public getLatestArrivalPage(limit, page): Observable<IResponse> {
    return this.api.getRequest('newproduct', 'list?limit='+limit + '&&page='+page).map( (res: IResponse) => {
      return res;
    });
  }
  public getLatestArrivalByCategory(id): Observable<IResponse> {
    return this.api.getRequest('categorynewproduct', 'list/' + id).map( (res: IResponse) => {
      return res;
    });
  }
  public getFeaturedProducts(): Observable<IResponse> {
    return this.api.getRequest('featuredproduct', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public getUser(id): Observable<IResponse> {
    return this.api.getRequest('customer/show', id).map( (res: IResponse) => {
      return res;
    });
  }
  public getWishLists(customerId): Observable<IResponse> {
    return this.api.getRequest('wishlist', 'list/' + customerId).map( (res: IResponse) => {
      return res;
    });
  }
  public getOrderLists(customerId): Observable<IResponse> {
    return this.api.getRequest('customer/order', 'list/' + customerId).map( (res: IResponse) => {
      return res;
    });
  }
  public getOrderDetails(orderId): Observable<IResponse> {
    return this.api.getRequest('orderdetails', 'list/' + orderId).map( (res: IResponse) => {
      return res;
    });
  }
  public listPaymentModes(): Observable<IResponse> {
    return this.api.getRequest('paymentmode', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public listVoucherDenomination(): Observable<IResponse> {
    return this.api.getRequest('voucherprices', null).map( (res: IResponse) => {
      return res;
    });
  }
  public getWallet(userId): Observable<IResponse> {
    return this.api.getRequest('wallet', 'current_balance').map( (res: IResponse) => {
      return res;
    });
  }
  public applyCoupon(data): Observable<IResponse> {
    return this.api.postRequest('order', 'coupondiscount', data).map( (res: IResponse) => {
      return res;
    });
  }
  public applyVoucher(data): Observable<IResponse> {
    return this.api.postRequest('order', 'voucher', data).map( (res: IResponse) => {
      return res;
    });
  }
  public fundWalletInit(data): Observable<IResponse> {
    return this.api.postRequest('wallet', 'initialise_payment', data).map( (res: IResponse) => {
      return res;
    });
  }
  public buyVoucherInit(data): Observable<IResponse> {
    return this.api.postRequest('vouchers', 'initializepayment', data).map( (res: IResponse) => {
      return res;
    });
  }
  public transferVoucher(data): Observable<IResponse> {
    return this.api.postRequest('vouchers', 'gift', data).map( (res: IResponse) => {
      return res;
    });
  }
  public emptyWishLists(customerId): Observable<IResponse> {
    return this.api.deleteRequest('wishlist', 'empty/' + customerId ).map( (res: IResponse) => {
      return res;
    });
  }
  public getProductById(id): Observable<IResponse> {
    return this.api.getRequest('product', 'show/' + id).map( (res: IResponse) => {
      return res;
    });
  }
  public logOut(): Observable<IResponse> {
    return this.api.postRequest('auth', 'logout', {}).map((res: IResponse)  => {
      return res ;
    });
  }

  public resetPassword(data): Observable<IResponse> {
    return this.api.postRequest('auth/change', 'password', data).map( (res: IResponse) => {
      return res;
    });
  }
  public verifyAccount(data): Observable<IResponse> {
    return this.api.postRequest('auth', 'verify-signup', data).map( (res: IResponse) => {
      return res;
    });
  }
  public verifyPayment(data): Observable<IResponse> {
    return this.api.postRequest('paystack/transaction', 'verify', data).map( (res: IResponse) => {
      return res;
    });
  }
  public walletVerifyFunding(ref): Observable<IResponse> {
    return this.api.getRequest('wallet', 'verify_funding/' + ref).map( (res: IResponse) => {
      return res;
    });
  }
  public voucherVerifyFunding(ref): Observable<IResponse> {
    return this.api.getRequest('vouchers', 'paymentverify/' + ref).map( (res: IResponse) => {
      return res;
    });
  }
  public verifyWebPayPayment(data): Observable<IResponse> {
    return this.api.postRequest('interswitch/transaction', 'verify', data).map( (res: IResponse) => {
      return res;
    });
  }
  public forgotPassword(data): Observable<IResponse> {
    return this.api.postRequest('auth', 'reset/password', data).map( (res: IResponse) => {
      return res;
    });
  }
  public updateUser(data): Observable<IResponse> {
    return this.api.putRequest('user', data.id, data).map( (res: IResponse) => {
      return res;
    });
  }
  public addToWishList(data): Observable<IResponse> {
    return this.api.postRequest('wishlist', 'create', data).map( (res: IResponse) => {
      return res;
    });
  }
  public addToCart(data): Observable<IResponse> {
    return this.api.postRequest('cart/product', 'add', data).map( (res: IResponse) => {
      return res;
    });
  }
  public addNewSubscriber(data): Observable<IResponse> {
    return this.api.postRequest('bookclub/mailinglist', 'create', data).map( (res: IResponse) => {
      return res;
    });
  }

  public addShopToCart(data): Observable<IResponse> {
    return this.api.postRequest('cart', null, data).map( (res: IResponse) => {
      return res;
    });
  }
  public updateCart(data): Observable<IResponse> {
    return this.api.putRequest('cart', 'product/update', data).map( (res: IResponse) => {
      return res;
    });
  }
  public checkoutFarmShop(data): Observable<IResponse> {
    return this.api.postRequest('checkout-cart', 'farm-shop', data).map( (res: IResponse) => {
      return res;
    });
  }
  public deleteCart(cartId): Observable<IResponse> {
    return this.api.deleteRequest('cart', cartId).map( (res: IResponse) => {
      return res;
    });
  }
  public deleteShippingAddress(shippingaddress_id): Observable<IResponse> {
    return this.api.deleteRequest('shippingaddress', 'softdelete/' + shippingaddress_id).map( (res: IResponse) => {
      return res;
    });
  }

  public getCountries(): Observable<IResponse> {
    return this.api.getRequest('country', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public getShippingAreas(): Observable<IResponse> {
    return this.api.getRequest('shippingarea', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public getShippingMethods(): Observable<IResponse> {
    return this.api.getRequest('shippingmethod', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public getCountryZones(countryId): Observable<IResponse> {
    return this.api.getRequest('country/zone', 'list/' + countryId ).map( (res: IResponse) => {
      return res;
    });
  }
 }
