import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs-compat/observable/throw';
import 'rxjs/add/operator/retryWhen';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/take';
import {RestfulHttpService} from '../httpService/service.service';
import {HttpClient, HttpParams} from '@angular/common/http';
// import * as jwt_decode from 'jwt-decode';

import { environment as env } from '../../../environments/environment';
import {isNullOrUndefined} from 'util';
import {EncryptDataService} from '../encryption/encrypt-data.service';
import {Router} from '@angular/router';
import {NotificationService} from '../notificationServices/notification.service';
import {BootstrapNotifyService} from '../bootstrap-notify/bootstrap-notify.service';
/**
 *
 * Service to handle all api calls to api backend
 */
@Injectable()
export class ApiService extends RestfulHttpService {
  constructor(http: HttpClient,
              private encryptionService: EncryptDataService,
              private router: Router,
              private alertService: BootstrapNotifyService,
              private notification: NotificationService) {
    super(http);
  }
  errorHandler(error) {
    try {
      console.info('Error ', error);
      if (error.status === 401 && (error.error.message.includes('Access denied') || error.error.message.includes('Unauthenticated') )) {
        sessionStorage.clear();
        (<any>$('.modal')).modal('hide');
        console.info('Error Clear ', error.error);
        localStorage.clear();
        this.router.navigate(['/']);
        this.alertService.advance(error.error.message);
        return throwError(error || {message: 'Unknown error occurred'});
      }
      return throwError(error || {message: 'Unknown error occurred'});
    } catch (error) {
      console.info('Type error occurred');
      return throwError(error || {message: 'Unknown error occurred'});
    }
  }
  successHandler(res) {
    try {
      if (res.message === 'Token Timeout' || res.message === 'Token Expired') {
        sessionStorage.clear();
        (<any>$('.modal')).modal('hide');
        localStorage.clear();
        this.router.navigate(['/']);
        this.alertService.advance(res.message);
        return res;
      }
      return res;
    } catch (error) {
      return res;
    }
  }
  public decode(res, auth?: string) {
    const data = res.data;
    if (res.message === 'Token Timeout' || res.message === 'Token Expired') {
      sessionStorage.clear();
      (<any>$('.modal')).modal('hide');
      localStorage.clear();
      this.router.navigate(['/']);
      this.alertService.advance(res.message);
      return res;
    }
    if (res && !isNullOrUndefined(res.data)) {
      if (auth && (auth.match('authenticate') || auth.match('account/create')
        || auth.match('register') || auth.match('login') )) {
        console.log('AUTH_APIS', data);
        sessionStorage.setItem(env.TOKEN, data.access_token);
        sessionStorage.setItem(env.USERTOKEN, JSON.stringify(data.user));
        sessionStorage.setItem(env.USERROLE, JSON.stringify(data.role));
      }
      return res;
    } else {
      return res;
    }
  }


  /**
   *
   * @param {string} api
   * @param {string} method
   * @param data
   * @returns {Observable<any>}
   */
  deleteRequest(api: string, method: string, data?: any): Observable<any> {
    let ENDPOINT;
    if (!isNullOrUndefined(method)) {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api + '/' + method;
    } else {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api;
    }
    return super.delete(ENDPOINT, data).retryWhen(error => {
      return error.mergeMap((err) => this.errorHandler(err))
        .delay(1000)
        .take(2);
    }).catch(this.errorHandler).map(res => this.successHandler(res));
  }


  /**
   *
   * @param {string} api
   // tslint:disable-next-line:no-redundant-jsdoc
   * @param {string} method
   * @param data
   * @returns {Observable<any>}
   */
  putRequest(api: string, method: string, data: any): Observable<any> {
    let ENDPOINT;
    if (!isNullOrUndefined(method)) {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api + '/' + method;
    } else {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api;
    }
    return super.put(ENDPOINT, data).retryWhen(error => {
      return error.mergeMap((err) => this.errorHandler(err))
        .delay(1000)
        .take(2);
    }).catch(this.errorHandler).map(res => this.successHandler(res));

  }


  /**
   *
   * @param {string} api
   * @param {string} method
   * @param data
   * @returns {Observable<any>}
   */
  patchRequest(api: string, method: string, data: any): Observable<any> {
    let ENDPOINT;
    if (!isNullOrUndefined(method)) {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api + '/' + method;
    } else {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api;
    }
    return super.patch(ENDPOINT, data).retryWhen(error => {
      return error.mergeMap((err) => this.errorHandler(err))
        .delay(1000)
        .take(2);
    }).catch(this.errorHandler).map(res => {
      return res;
    });
  }


  /**
   *
   * @param {string} api
   * @param {string} method
   * @param {HttpParams} params
   * @returns {Observable<any>}
   */
  getRequest(api: string, method: string, params?: HttpParams): Observable<any> {
    let ENDPOINT;
    // console.info(api);
    if (!isNullOrUndefined(method)) {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api + '/' + method;
    } else {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api;
    }
    // console.info(ENDPOINT);
    return super.get(ENDPOINT, params).retryWhen(error => {
      return error.mergeMap(err => this.errorHandler(err))
        .delay(1000)
        .take(2);
    }).catch(this.errorHandler).map(res => this.successHandler(res));
  }


  /**
   *
   * @param {string} api
   * @param {string} method
   * @param data
   * @returns {Observable<any>}
   */
  postRequest(api: string, method: string, data: any): Observable<any> {
    let ENDPOINT;
    if (!isNullOrUndefined(method) && method !== 'authenticate') {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api + '/' + method;
    } else {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api;
    }
    return super.post(ENDPOINT, data).retryWhen(error => {
      return error.mergeMap((err) => this.errorHandler(err))
        .delay(1000)
        .take(2);
    }).catch(this.errorHandler).map(res => this.decode(res, method));

  }
}
