import { Component, OnInit } from '@angular/core';
import {NavigatorService} from '../../../services/navigatorService/navigator.service';
import {UserService} from '../../../services/apiCalls/userService/user.service';
import {BootstrapNotifyService} from '../../../services/bootstrap-notify/bootstrap-notify.service';
import {UtilService} from '../../../services/utilService/util.service';
import {IResponse} from '../../../interfaces/iresponse';

@Component({
  selector: 'app-right-sider',
  templateUrl: './right-sider.component.html',
  styleUrls: ['./right-sider.component.css']
})
export class RightSiderComponent implements OnInit {
  loading = true;
  featuredProducts: any[] = [];
  Products: any[] = [];
  currentUser = null;
  constructor(private navigatorService: NavigatorService,
              private userService: UserService,
              private alertService: BootstrapNotifyService,
              private utilService: UtilService) { }

  ngOnInit() {
    this.currentUser =  this.utilService.getAuthUser();
  }/*
  getProducts() {
    this.loading = true;
    this.userService.getProducts()
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.Products = res.data;
        this.featuredProducts = this.Products.slice(0, 6);
        console.log('featuredProducts ', this.featuredProducts);
        this.loading = false;
      }, error => {
        console.log('Error ', error);
        this.loading = false;
      });
  }*/
  addToWishlist(product) {
    console.log('Product ', product, this.currentUser);
    if (!this.currentUser) {
      this.navigatorService.navigateUrl('/login');
    } else {
      this.userService.addToWishList({
        product_id: product.product.product_id, customer_id: this.currentUser.customer_id})
        .subscribe((res: IResponse) => {
          console.log('Res ', res);
          this.alertService.success('Item added to wishlist!');
        }, error => {
          console.log('Erro ', error);
        });
    }
  }
}
