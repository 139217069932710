import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../authService/auth.service';
import {Observable} from 'rxjs';
import * as JWT_DECODE from 'jwt-decode';
import {CacheService} from '../cacheService/cache.service';
import {EventsService} from '../eventServices/event.service';
import {DecryptService} from '../decryptService/decrypt.service';
import {environment as ENV} from '../../../environments/environment';
import {BootstrapNotifyService} from '../bootstrap-notify/bootstrap-notify.service';
@Injectable()
export class GuardService implements CanActivate {
  url: string;
  constructor( private router: Router, private eventsService: EventsService,
               private decrypt: DecryptService,
               private bootstrapNotify: BootstrapNotifyService,
               private authService: AuthService, private cache_: CacheService, private cacheService: CacheService) {
  }

  /**
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<boolean> | Promise<boolean> | boolean}
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.url = state.url;
    console.info('URL =>>>>>>> ', this.url);
    return this.checkLogin(this.url);
  }
  /**
   * Check member login
   * @param {string} url
   * @returns {boolean}
   */
  checkLogin(url?: string): boolean {
    // return true;
    const user =  this.cacheService.getStorage(ENV.TOKEN);
    console.log('USER ', user );
    if (this.authService.loggedIn(url)) { return true; }
    this.router.navigate([ '/' ]); // then ask user to login
    this.logOut();
    return false;
  }

  /**
   * Log out from system
   */
  logOut() {
    this.cache_.clearStorage();
    this.cache_.clearSession();
  }

}
export class RoleService implements CanActivate {
  static getAuthUser() {
    return JSON.parse(sessionStorage.getItem(ENV.USERTOKEN));
  }
  static getAuthUserRole() {
    return JSON.parse(sessionStorage.getItem(ENV.USERROLE));
  }
  static checkRole(roles): boolean {
    if (!roles.length) { return false; }
    const user = RoleService.getAuthUser();
    const userRole = RoleService.getAuthUserRole();
    const _userRole = user && userRole.toLowerCase();
    if (_userRole === 'superadmininistrator' && roles.includes('superadmininistrator') && roles[0] === 'superadmininistrator') {
      return true;
    } else if (_userRole === 'vendor' && roles.includes('vendor') && roles[0] === 'vendor') {
      return true;
    } else if (_userRole === 'customer' && roles.includes('customer') && roles[0] === 'customer') {
      return true;
    } else {
      return false;
    }/* else {
      if (_userRole === 'user') {
        const userDataRoles = user.roles;
        for (const role of userDataRoles) {
          if (roles.includes(role.name)) {
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    }*/
  }
  constructor( ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const roles: any = route.data;
    // console.log('ROLE DATA ', roles);
    return RoleService.checkRole(roles.roles);
  }
}
