import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthService} from 'angularx-social-login';
import {Subject} from 'rxjs/Subject';
import {takeUntil} from 'rxjs/internal/operators';
import {BootstrapNotifyService} from '../../../services/bootstrap-notify/bootstrap-notify.service';
import {NavigatorService} from '../../../services/navigatorService/navigator.service';
import {EventsService} from '../../../services/eventServices/event.service';

@Component({
  selector: 'app-social-auth',
  templateUrl: './social-auth.component.html',
  styleUrls: ['./social-auth.component.css']
})
export class SocialAuthComponent implements OnInit, AfterViewInit, OnDestroy {
@Input() type = 'login';
  public user = {
    email: null,
    firstname: null,
    lastname: null,
    telephonenumber: null,
    referral_email: null,
    channel_notice: null,
    accept_term: false,
    accessToken: null,
    access_token: null,
    auth_type: null,
    country_id: 156,
    idToken: null,
    signInToken: null,
    zone_id: 2412,
    countrycode: 234,
    address: null,
    city: null,
    authMode: this.type

  };
  private ngUnsubscribe = new Subject();

  constructor(private socialAuth: SocialAuthService,
              private bootstrapNotify: BootstrapNotifyService,
              private navigateService: NavigatorService, private eventService: EventsService) {
    this.eventService.on('SOCIALLOGOUT', () => {
      console.log('received redirect');
      this.logoutAndRoute();
    });
  }

  ngOnInit() {
    this.socialAuth.signOut(true).catch(() => {});
  }
  ngAfterViewInit() {
    const subscriber = this.socialAuth.authState
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user) => {
        if (user) {
          console.log('USERS from SOCIAL', user);
          this.user = {
            email: user.email,
            firstname: user.firstName,
            lastname: user.lastName,
            telephonenumber: null,
            referral_email: null,
            channel_notice: null,
            accept_term: false,
            accessToken: (user.provider === 'FACEBOOK') ? user.authToken : user.idToken,
            access_token: (user.provider === 'FACEBOOK') ? user.authToken : user.idToken,
            idToken: user.idToken,
            signInToken: user.authToken,
            auth_type: user.provider,
            country_id: 156,
            zone_id: 2412,
            countrycode: 234,
            address: null,
            city: null,
            authMode: this.type
          };
          sessionStorage.setItem('AUTH', JSON.stringify(this.user));
          if (this.type === 'login') {
            this.eventService.broadcast('SOCIAL_SIGNIN', this.user);
            subscriber.unsubscribe();
          } else {
            this.eventService.broadcast('SOCIAL_SIGNUP', this.user);
            subscriber.unsubscribe();
          }
        }
      }, error => {
        console.log('AUTH ERROR ', error);
      });
  }




  signInWithGoogle(): void {
    this.socialAuth.signIn(GoogleLoginProvider.PROVIDER_ID).catch(() => {});
  }
  signInWithFB(): void {
    this.socialAuth.signIn(FacebookLoginProvider.PROVIDER_ID).catch(() => {});
  }

  logoutAndRoute() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.socialAuth.signOut(true).then(() => {
      this.navigateService.navigateUrl('/login');
    }).catch(() => {
      this.navigateService.navigateUrl('/login');
    });
  }
  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    // this.socialAuth.signOut(true).catch(() => {});
  }
}
