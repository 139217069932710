import {Component, Input, OnInit} from '@angular/core';
import {UtilService} from '../../../../services/utilService/util.service';
// import * as $ from 'jquery';
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  @Input() dataInput: any;
  currentUser = null;
  pageTitle: string;
  userRole: string;
  constructor(private utilService: UtilService) { }

  ngOnInit() {
    this.pageTitle = 'Dashboard';
    this.currentUser = this.utilService.getAuthUser();
    this.userRole = this.utilService.getAuthUserRole();
    console.log('USER data Input ', this.dataInput);
    this.pageTitle = this.dataInput.name;

  }
}
