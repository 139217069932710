import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NavigatorService} from '../../../../services/navigatorService/navigator.service';
import {BootstrapNotifyService} from '../../../../services/bootstrap-notify/bootstrap-notify.service';
import { environment as ENV } from '../../../../../environments/environment';
import {IResponse} from '../../../../interfaces/iresponse';
import {UserService} from '../../../../services/apiCalls/userService/user.service';

@Component({
  selector: 'app-l-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, AfterViewInit {
  subscribe: string =  null;
  EMAIL_VALIDATION = ENV.EMAIL_VALIDATION;
  constructor(private navigate: NavigatorService,
              private notifyService: BootstrapNotifyService,
              private userService: UserService) { }

  ngOnInit() {
  }
  ngAfterViewInit() {  }
  public navigateToPage() {
    this.navigate.navigateUrl('/user/dashboard');
  }

  public subscribeToNewsletter() {
    console.log('Subscriber ', this.subscribe);
    if (this.subscribe && this.subscribe.match(this.EMAIL_VALIDATION)) {
      this.userService.addNewSubscriber({emailaddress: this.subscribe, fullname: "Anonymous"+ new Date()}).subscribe((res: IResponse) => {
          this.notifyService.success(res.message || 'Email added to subscriber list successfully!');
      }, error => {
        this.notifyService.error(error.error.msg || 'Unable to add email to list of subscribers, please retry!');
      });
    } else {
      this.notifyService.error('Invalid Email Address!');
    }
  }

}
