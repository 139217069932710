import { Component, OnInit } from '@angular/core';
import {NavigatorService} from '../../../services/navigatorService/navigator.service';
import {UserService} from '../../../services/apiCalls/userService/user.service';
import {BootstrapNotifyService} from '../../../services/bootstrap-notify/bootstrap-notify.service';
import {UtilService} from '../../../services/utilService/util.service';
import {IResponse} from '../../../interfaces/iresponse';
import {CacheService} from "../../../services/cacheService/cache.service";

@Component({
  selector: 'app-similar-products',
  templateUrl: './similar-products.component.html',
  styleUrls: ['./similar-products.component.css']
})
export class SimilarProductsComponent implements OnInit {
  loading = true;
  relatedProducts: any[] = [];
  Products: any[] = [];
  currentUser = null;
  constructor(private navigatorService: NavigatorService,
              private userService: UserService,
              private alertService: BootstrapNotifyService,
              private cacheService: CacheService,
              private utilService: UtilService) {
    this.loading = true;
    this.getProducts();
  }

  ngOnInit() {
    this.currentUser =  this.utilService.getAuthUser();
  }
  getProducts() {
    this.relatedProducts = JSON.parse(this.cacheService.getStorage('SIMILAR_PRODUCTS')) || [];
    this.loading = false;
  }

  addToWishlist(product) {
    this.utilService.addToWishlist(product);
  }
  addToCart(product) {
    this.utilService.addToCart(product);
  }

}
