import { Component, OnInit } from '@angular/core';
import {NavigatorService} from "../../services/navigatorService/navigator.service";
import {UserService} from "../../services/apiCalls/userService/user.service";
import {BootstrapNotifyService} from "../../services/bootstrap-notify/bootstrap-notify.service";
import {UtilService} from "../../services/utilService/util.service";
import {IResponse} from "../../interfaces/iresponse";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-view-product-details',
  templateUrl: './view-product-details.component.html',
  styleUrls: ['./view-product-details.component.css']
})
export class ViewProductDetailsComponent implements OnInit {
  loading = true;
  loadingProduct = true;
  bestSellers_1: any[] = [];
  bestSellers_2: any[] = [];
  Products: any[] = [];
  reviews: any[] = [];
  currentUser = null;
  review = {
    customer_id: 0,
    product_id: 0,
    comment: null,
    rating: 4,
    author: null
  };
  selectedProduct = null;
  rating = 0;
  imageToShow = null;
  constructor(private navigatorService: NavigatorService,
              private userService: UserService,
              private alertService: BootstrapNotifyService,
              private route: ActivatedRoute,
              private utilService: UtilService) {
    const productId = parseInt(this.route.snapshot.paramMap.get('productId'), 10) || null;
    if (!productId || isNaN(productId)) {
      this.alertService.info('Invalid product selected');
    } else {
      this.getSelectedProduct(productId);
    }
    this.getBestSellers();
    this.getReviews();
  }

  ngOnInit() {
    const productName = this.route.snapshot.paramMap.get('productName');
    this.utilService.setPageTitle(productName);
    this.currentUser =  this.utilService.getAuthUser();
  }

  getBestSellers() {
    this.loading = true;
    this.userService.getBestSellersProducts()
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.Products = res.data;
        this.bestSellers_1 = this.Products.slice(0, 3);
        this.bestSellers_2 = this.Products.slice(3, 6);
        this.loading = false;
      }, error => {
        console.log('Error ', error);
        this.loading = false;
      });
  }
  imageToDisplay(img) {
    this.imageToShow = img;
  }
  getSelectedProduct(productId) {
    this.loadingProduct = true;
    this.userService.getProductById(productId)
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.selectedProduct = res.data[0];
        this.utilService.setPageTitle(this.selectedProduct.product.product_name, this.selectedProduct.product.manufacturer_name);
        this.imageToShow = this.selectedProduct.images[0].image;
        // this.selectedProduct.images = this.selectedProduct.images.slice(0, 4);
        const rating = parseInt(this.selectedProduct.product.reviewaverage, 10) || 0;
        this.rating = (rating / 5 ) * 100;
        this.loadingProduct = false;
        // this.processImages();
      }, error => {
        console.log('Error ', error);
        this.loadingProduct = false;
      });
  }


  processImages() {
    this.selectedProduct.images.forEach(img => {

      const image = new Image();
      image.src = img.image;
      image.onload = function () {
        // Determine the Height and Width.
        const OutHeight: any = (<HTMLInputElement>this).height;
        const OutWidth: any = (<HTMLInputElement>this).width;

        console.log('heig ', OutHeight, 'wid ', OutWidth);
        img.width = parseInt(OutWidth, 10);
        img.height = parseInt(OutHeight, 10);
        img.great = parseInt(OutHeight, 10) > parseInt(OutWidth, 10);

      };


    })
  }


  addToWishlist(product) {
    console.log('Product ', product, this.currentUser);
    this.utilService.addToWishlist(product);
  }
  addToCart(selectedProduct) {
    const value: any = $('#quantityValue').val() || 0;
    console.log('Value ', value);
    // const quantity = parseInt(value, 10) || 1;
    this.utilService.addToCart(selectedProduct, parseInt(value, 10) || 1);
  }
  reviewProduct() {
    if (this.currentUser) {
      this.customerReview();
    } else {
      this.guessReview();
    }
  }
  customerReview() {
    this.userService.customerReview({
      customer_id: this.currentUser.customer_id,
      product_id: parseInt(this.route.snapshot.paramMap.get('productId'), 10),
      comment: this.review.comment,
      rating: this.review.rating
    }).subscribe((res: IResponse) => {
      this.alertService.success('Review saved successful!');
      console.log({res});
      this.getReviews();
      this.resetForm();
    }, error => {
      console.log({error});
      this.alertService.error('Unable to add review!');
      this.resetForm();
    });
  }
  getReviews() {
    this.userService.getProductReviews(parseInt(this.route.snapshot.paramMap.get('productId'), 10))
      .subscribe((res: IResponse) => {
      console.log('REVIEWS ', res);
      this.reviews = res.data;
      }, error => {
      console.log({error});
    });
  }
  resetForm() {
    this.review = {
      customer_id: 0,
      product_id: 0,
      comment: null,
      rating: 4,
      author: null
    };
  }
  guessReview() {
    this.userService.guestReview({
      author: this.review.author,
      product_id: parseInt(this.route.snapshot.paramMap.get('productId'), 10),
      comment: this.review.comment,
      rating: this.review.rating
    }).subscribe((res: IResponse) => {
      this.alertService.success('Review saved successful!');
      console.log({res});
      this.getReviews();
      this.resetForm();
    }, error => {
      console.log({error});
      this.alertService.error('Unable to add review!');
      this.resetForm();
    });
  }
  notifyMeWhenAvailable() {
    this.userService.notifyMe({
      customer_id: this.currentUser.customer_id,
      product_id: this.route.snapshot.paramMap.get('productId'),
    }).subscribe((res: IResponse) => {
      this.alertService.success('You will be notified once this product is stocked');
    }, error => {
      console.log({error});
      this.alertService.error(error.error.message || 'Unable to save notify me!');
      this.resetForm();
    });
  }
}
