import {AfterViewInit, Component, OnInit} from '@angular/core';
import {UtilService} from '../../services/utilService/util.service';
import {UserService} from '../../services/apiCalls/userService/user.service';
import {IResponse} from '../../interfaces/iresponse';
import {ActivatedRoute} from '@angular/router';
import {EventsService} from '../../services/eventServices/event.service';
import {CacheService} from "../../services/cacheService/cache.service";

@Component({
  selector: 'app-all-products',
  templateUrl: './all-products.component.html',
  styleUrls: ['./all-products.component.css']
})
export class AllProductsComponent implements OnInit, AfterViewInit {
  loading = true;
  limit = 20;
  page = 1;
  start_output = 1;
  end_output = 20;
  total_results = 20;
  allProducts = [];
  categories = [];
  subCategories = [];
  currentUser = null;
  authors = [];
  categoryId = null;
  loaders = {
    loading: true
  };
  filter = {
    author: null,
    minprice: 0,
    maxprice: 100,
    category_id: null
  };
  filtering = false;
  action = '+';
  operation = 'category';
  optionKeeper = null;
  constructor(private utilService: UtilService,
              private route: ActivatedRoute,
              private cacheService: CacheService,
              private eventService: EventsService,
              private userService: UserService) {
    this.categoryId = this.route.snapshot.queryParamMap.get('categoryId') || null;
    this.getProducts(this.categoryId, this.route.snapshot.queryParamMap.get('title') || null);
    this.getCategories();
    this.eventService.on('SEARCH_PRODUCT', (option) => {
      this.optionKeeper = option;
      this.operation = 'search';
      this.searchProduct(option, this.limit, this.page);
    });
  }

  ngOnInit() {
    this.currentUser =  this.utilService.getAuthUser();
  }
  ngAfterViewInit() {
    this.getAuthors();
  }

  getProducts(categoryId = null, title = null) {
    // console.log('this.route.snapshot.queryParamMap.get', this.route.snapshot.queryParamMap.get('title') );
    this.loading = true;
    this.filter.category_id = this.categoryId;
    if (!categoryId && title) {
      this.operation = 'search';
      this.optionKeeper = { search: title};
      this.searchProduct(this.optionKeeper, this.limit, this.page);
    } else if (categoryId && title) {
      this.operation = 'search';
      this.optionKeeper = {category_id: categoryId, search: title};
      this.searchProduct(this.optionKeeper, this.limit, this.page);
    } else if (categoryId && !title ) {
      this.operation = 'get_by_category';
      this.getProductsByCategory(categoryId, this.limit, this.page);
    } else {
      this.operation = 'get_all';
      this.getAllProducts();
    }
  }

  public scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  getAllProducts() {
    this.userService.getProducts(this.limit, this.page)
      .subscribe((res: IResponse) => {
        console.log('Response 3', res);
        this.allProducts = res.data;
        this.utilService.setPageTitle(this.allProducts[0] && this.allProducts[0].categories[0] && this.allProducts[0].categories[0].category_name);
        this.total_results = res.total_number || this.limit;
        if(this.allProducts.length) {
          this.start_output = (( this.limit * this.page ) - this.limit) + 1;
          if(this.page === 1) {
            this.end_output = this.allProducts.length;
          } else {
            if(this.action === '-'){
              this.end_output -=this.allProducts.length;
            } else {
              this.end_output +=this.allProducts.length;
            }
          }
        }
        this.loading = false;
        this.scrollTop();
      }, error => {
        console.log('Error ', error);
        this.loading = false;
      });
  }
  getPrevious() {
    if(this.page === 1) {
      return false;
    }
    this.action = '-';
    this.page -= 1;
    this.performOperation();
  }
  getNext() {
    if(!this.allProducts.length) {
      return false;
    }
    this.action = '+';
    this.page += 1;
    this.performOperation();
  }
  performOperation() {
    this.loading = true;
    switch(this.operation) {
      case 'filter': {
        this.filter.category_id = this.categoryId;
        this.scrollTop();
        this.filterProducts();
        break;
      }
      case 'get_all': {
        this.scrollTop();
        this.getAllProducts();
        break;
      }
      case 'get_by_category': {
        this.scrollTop();
        this.getProductsByCategory(this.categoryId, this.limit, this.page);
        break;
      }
      case 'search': {
        this.scrollTop();
        this.searchProduct(this.optionKeeper, this.limit, this.page);
        break;
      }
      default: {
        this.scrollTop();
        this.getAllProducts();
        break;
      }
    }
  }
  getAuthors() {
    this.userService.getAuthors().subscribe((res: IResponse) => {
      console.log('Authors ', res);
      this.authors = res.data;
    }, error => {
      console.log('Manufacturer ', error);
    });
  }
  getProductsByCategory(catId, limit, page) {
    this.userService.getProductsByCategory(catId, limit, page)
      .subscribe((res: IResponse) => {
        console.log('Response 1', res);
        this.allProducts = res.data;
        this.utilService.setPageTitle(this.allProducts[0] && this.allProducts[0].categories[0] && this.allProducts[0].categories[0].category_name);
        this.total_results = res.total_number || this.limit;
        if(this.allProducts.length) {
          this.start_output = (( this.limit * this.page ) - this.limit) + 1;
          if(this.page === 1) {
            this.end_output = this.allProducts.length;
          } else {
            if(this.action === '-'){
              this.end_output -=this.allProducts.length;
            } else {
              this.end_output +=this.allProducts.length;
            }
          }
        }
        this.loading = false;
        this.scrollTop();

      }, error => {
        console.log('Error ', error);
        this.loading = false;
      });
  }
  searchProduct(option, limit, page) {
    this.userService.searchProducts(option, limit, page)
      .subscribe((res: IResponse) => {
        console.log('Response 2', res);
        this.allProducts = res.data;
        this.utilService.setPageTitle(this.allProducts[0] && this.allProducts[0].categories[0] && this.allProducts[0].categories[0].category_name);

        this.total_results = res.total_number || this.limit;
        if(this.allProducts.length) {
          this.start_output = (( this.limit * this.page ) - this.limit) + 1;
          if(this.page === 1) {
            this.end_output = this.allProducts.length;
          } else {
            if(this.action === '-'){
              this.end_output -=this.allProducts.length;
            } else {
              this.end_output +=this.allProducts.length;
            }
          }
        }
        this.loading = false;
        this.scrollTop();
        this.cacheService.deleteStorage('SIMILAR_PRODUCTS');
      }, error => {
        console.log('Error ', error);
        this.loading = false;
      });
  }
  getCategories() {
    this.userService.getCategories().subscribe((res: IResponse) => {
      this.categories = res.data;
      this.getSubCategories(this.categoryId || this.categories[0].category_id);
    }, error => {
      console.log({error});
    });
  }
  getSubCategories(cat_id) {
    this.loaders.loading = true;
    this.userService.getSubCategories(cat_id).subscribe((res: IResponse) => {
      console.log('RES ', res);
      this.loaders.loading = false;
      this.subCategories = res.data;
    }, error => {
      this.loaders.loading = false;
      console.log({error});
    });
  }
  addToWishlist(product) {
    this.utilService.addToWishlist(product);
  }
  addToCart(product) {
    this.utilService.addToCartWithoutModal(product);
  }
  viewProductDetails(event, product) {
    event.preventDefault();
    console.log('View search result ');
    window.location.href = `${window.location.origin}/products/view-detail/${product.product.product_id}/${product.product.product_name.split(' ').join('_').split(')').join('_').split('(').join('_').split('/').join('_').toLowerCase()}`;
    this.cacheService.setStorage('SIMILAR_PRODUCTS', JSON.stringify(product.similarproducts || []));
  }
  filterProducts() {
    this.filtering = true;
    this.loading = true;
    this.operation = 'filter';
    let minVal = parseFloat($('.noUi-handle-lower').attr('aria-valuetext') as any);
   let maxVal = parseFloat($('.noUi-handle-upper').attr('aria-valuetext') as any);
    this.filter.minprice= minVal;
    this.filter.maxprice= maxVal;
    this.userService.filterProducts(this.filter, this.limit, this.page).subscribe((res: IResponse) => {
     console.log('RES 4', res);
     this.filtering = false;
      this.loading = false;
      this.allProducts = res.data;
      this.utilService.setPageTitle(this.allProducts[0] && this.allProducts[0].categories[0] && this.allProducts[0].categories[0].category_name);
      this.total_results = res.total_number || this.limit;
      if(this.allProducts.length) {
        this.start_output = (( this.limit * this.page ) - this.limit) + 1;
        if(this.page === 1) {
          this.end_output = this.allProducts.length;
        } else {
          if(this.action === '-'){
            this.end_output -=this.allProducts.length;
          } else {
            this.end_output +=this.allProducts.length;
          }
        }
      }
      this.scrollTop();
    }, error => {
     console.log('Error ', error);
      this.loading = false;
      this.filtering = false;
   })
  }
}
