import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {landingRouting} from './app.routing';
import {CoreModule} from './shared/modules/core/core.module';
import {SharedModules} from './shared/modules/shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';

@NgModule({
  declarations: [
    AppComponent,
    landingRouting.components,
  ],
  imports: [
    BrowserModule,
    landingRouting.routes,
    CoreModule,
    SharedModules,
    SocialLoginModule,
    ScrollToModule.forRoot(),
    BrowserAnimationsModule,
  ],
  providers: [
    landingRouting.providers,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '317143237095-bhbdddh70s7e48r9f2hpp7gkoikf1eio.apps.googleusercontent.com'
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('2904401176486280'),
          }
        ],
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
