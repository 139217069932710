/**
 *
 * Created By Arokoyu Olalekan Ojo <arokoyuolalekan@gmail.com> @ 8/9/2019
 *
 */
import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CacheService} from '../cacheService/cache.service';
import {HttpClient} from '@angular/common/http';
import { environment as ENV } from '../../../environments/environment';
import * as JWT_DECODE from 'jwt-decode';
import {DecryptService} from '../decryptService/decrypt.service';
import {UtilService} from '../utilService/util.service';
import {UserService} from '../apiCalls/userService/user.service';
import {EventsService} from '../eventServices/event.service';
import {NavigatorService} from '../navigatorService/navigator.service';

@Injectable()
export class AuthService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private userService: UserService,
    private decryptData: DecryptService,
    private navigateService: NavigatorService,
    private utilService: UtilService,
    private eventService: EventsService,
    private activatedRoute: ActivatedRoute, private cache: CacheService) {
  }

  /**
   *
   * @returns {boolean}
   */
  public loggedIn(url?: string): boolean {
    return  this.checkUserLogin(url);
  }
  /**
   *
   * @returns {boolean}
   */
  public checkPrivilege(url): boolean {
    const userRole = this.utilService.getAuthUserRole();
    console.log('USER role ', userRole);
    return userRole.toLowerCase() === 'customer' || userRole.toLowerCase() === 'user';
  }

  /**
   * Ensure user / member sign in
   * @returns {boolean}
   */
  public checkUserLogin(url?: string): boolean {
    console.log('ROLE : HELLO WORLD');
    if (this.checkPrivilege(url)) {
      const userObject = this.utilService.getAuthUser();
      const userToken = sessionStorage.getItem(ENV.TOKEN);
      const userRole = sessionStorage.getItem(ENV.USERROLE);
      console.log('ROLE ', userRole, userToken, userObject);
      if (userObject === undefined || userObject === '' || userObject === null || !userToken || !userRole) {
        this.cache.deleteSession(ENV.TOKEN);
        this.cache.deleteSession(ENV.USERROLE);
        this.cache.deleteSession(ENV.USERTOKEN);
        this.cache.deleteStorage(ENV.USERTOKEN);
        this.cache.deleteStorage(ENV.USERROLE);
        this.cache.deleteStorage(ENV.TOKEN);
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }


  /**
   *
   * @returns {boolean}
   */
  public logOut() {
    this.cache.clearSession();
    this.cache.clearStorage();
    this.eventService.broadcast('NEW_LOGIN');
    return true;
  }
  public logUserOut() {
    this.userService.logOut().subscribe(() => {
      this.logOut();
    });
    this.logOut();
    window.location.assign('/');
  }
  public handleRedirect(response) {
    this.navigateService.navigateUrl(`/user/dashboard`);

    /*if (response.data.user.role.toLowerCase() === 'user' && roles.length === 1 && roles[0].name === 'frsc') {
    } else {
      this.navigateService.navigateUrl(`/${response.data.user.role.toLowerCase()}/dashboard`);
    }*/
  }
}
