import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UtilService} from '../../services/utilService/util.service';


@Component({
  selector: 'app-user-routes',
  templateUrl: './user-routes.component.html',
  styleUrls: ['./user-routes.component.css'],
  // encapsulation: ViewEncapsulation.None
})
export class UserRoutesComponent implements OnInit {

  constructor(private utilService: UtilService) {
    // this.navigatorService.navigateUrl('user/dashboard');
  }

  ngOnInit() {
    this.utilService.processCommonJs2();
  }
}
