import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UtilService} from './services/utilService/util.service';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class AppComponent implements OnInit {
  constructor(private utilServices: UtilService) {}
  ngOnInit() {
    const cssRule = 'color: #51BE50;' +
      'font-size: 20px;' +
      'text-align: center' +
      'font-weight: bold;' +
      'text-shadow: 1px 1px 5px rgb(249, 162, 34);' +
      'filter: dropshadow(color=#89DB87, offx=1, offy=1);';
    setTimeout(console.info.bind(console, '%cLaternal Books', cssRule), 0);
  }
}
