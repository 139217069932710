import { Component, OnInit } from '@angular/core';
import {UserService} from "../../services/apiCalls/userService/user.service";
import {IResponse} from "../../interfaces/iresponse";
import {UtilService} from "../../services/utilService/util.service";
import {BootstrapNotifyService} from "../../services/bootstrap-notify/bootstrap-notify.service";

@Component({
  selector: 'app-best-sellers',
  templateUrl: './best-sellers.component.html',
  styleUrls: ['./best-sellers.component.css']
})
export class BestSellersComponent implements OnInit {
  categories = [];
  bestSeller = [];
  loading = false;
  constructor(private userService: UserService, private utilService: UtilService,
              private alertService: BootstrapNotifyService) {
    this.getCategories();
  }

  ngOnInit() {
    this.utilService.setPageTitle('Best sellers');
    this.getBestsellers();
  }

  getCategories() {
    this.userService.getCategories().subscribe((res: IResponse) => {
      this.categories = res.data;
    }, error => {
      console.log({error});
    });
  }
  getBestsellers() {
    this.userService.getBestSellersProducts().subscribe((res: IResponse) => {
      console.log('RES BST SELLER ', res);
      this.bestSeller = res.data;
    }, error => {
      console.log('BEST SELLER Error ', error);
    } );
  }
  addToWishlist(product) {
    this.utilService.addToWishlist(product);
  }
  addToCart(product) {
    this.utilService.addToCart(product);
  }
  getProductsByCategory(category) {
    this.loading = true;
    this.userService.getBestSellerByCategory(category)
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.bestSeller = res.data;
        console.log('BEST SELLERS ', this.bestSeller);
        this.loading = false;
      }, error => {
        console.log('Error ', error);
        this.alertService.error(error.error.message);
        this.loading = false;
      });
  }
  filterByCategory() {
    const category = $('#categorySort').val();
    if (category === 'menu_order') {
      this.getBestsellers();
    } else {
      this.getProductsByCategory(category);
    }
  }
}
