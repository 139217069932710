import { Component, OnInit } from '@angular/core';
import {UtilService} from '../../services/utilService/util.service';
import {IResponse} from '../../interfaces/iresponse';
import {UserService} from '../../services/apiCalls/userService/user.service';
import {EventsService} from '../../services/eventServices/event.service';
import {ActivatedRoute} from '@angular/router';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {NavigatorService} from '../../services/navigatorService/navigator.service';
import {CacheService} from '../../services/cacheService/cache.service';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit {
  breadCrumb = {
    name: 'Wallet',
    parent: 'Home',
    subLink: null
  };
  currentUser = null;
  walletHistory = [];
  loading = false;
  transaction_reference = null;
  constructor(private utilService: UtilService,
              private route: ActivatedRoute,
              private navigateService: NavigatorService,
              private eventService: EventsService,
              private cacheService: CacheService,
              private alertService: BootstrapNotifyService,
              private userService: UserService) {
    const query: any = this.route.snapshot.queryParams;
    this.transaction_reference = query.trxref || query.reference;
    // this.paymentWebPayId = this.route.snapshot.queryParamMap.get('txn_ref') || null;
    this.eventService.on('RELOAD_HISTORY', () => {
      this.getWalletHistory();
    });
  }

  ngOnInit() {
    this.currentUser = this.utilService.getAuthUser();
    console.info('Current User', this.currentUser);
    this.getWalletHistory();
    if (this.transaction_reference) {
      this.alertService.success('Redirect from payment gateways');
      this.verifyingPayment(this.transaction_reference);
    }
  }

  getWalletHistory() {
    this.loading = true;
    this.walletHistory = [];
    this.userService.getWalletHistory()
      .subscribe((res: IResponse) => {
        console.log('Wallet History ', res);
        this.walletHistory = res.data || [];
        this.loading = false;
      }, error => {
        this.loading = false;
      });
  }
  public triggerFundWallet() {
    this.utilService.openModal('fundWallet');
  }
  public verifyingPayment(transaction_reference = null) {
    this.alertService.info('Verifying wallet  funding, please wait!');
    this.userService.walletVerifyFunding(transaction_reference)
      .subscribe((res: IResponse) => {
        console.log('Res ', res);
        this.alertService.success(res.message || 'Transaction successfully verified');
        this.cacheService.deleteStorage('TRANSACTION_REFERENCE');
        this.navigateService.navigateUrl('/user/wallet');
      }, err => {
        console.log('Err ', err);
        const refCode = this.cacheService.getStorage('TRANSACTION_REFERENCE');
        if (refCode) {
          this.verifyingPayment(refCode);
          this.cacheService.deleteStorage('TRANSACTION_REFERENCE');
        } else {
          this.navigateService.navigateUrl('/user/wallet');
          this.eventService.broadcast('RELOAD_HISTORY');
          this.alertService.error(err.error.message || 'Unable to verify transaction with that code');
        }
      });
  }
}
