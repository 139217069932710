import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/apiCalls/userService/user.service';
import {UtilService} from '../../services/utilService/util.service';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {IResponse} from '../../interfaces/iresponse';
import {Subject} from 'rxjs/Rx';
import {CacheService} from "../../services/cacheService/cache.service";
import {NavigatorService} from "../../services/navigatorService/navigator.service";
import {EventsService} from "../../services/eventServices/event.service";

@Component({
  selector: 'app-view-cart',
  templateUrl: './view-cart.component.html',
  styleUrls: ['./view-cart.component.css']
})
export class ViewCartComponent implements OnInit {
  public currentUser = null;
  loading = true;
  cartItems = [];
  userQuestionUpdate = new Subject<string>();
  cartItems_ = [];
  shippingAreas = [];
  totalInCart = 0.0;
  cartPath = window.location.origin + '/my-cart';
  constructor(private utilService: UtilService, private userService: UserService,
              private cacheService: CacheService,
              private navigatorService: NavigatorService,
              private eventService: EventsService,
              private alertService: BootstrapNotifyService) {
    this.userQuestionUpdate
      .distinctUntilChanged()
      .debounceTime(1000).subscribe((event: any) => {
      console.log('Hello world', event);
      this.validateQty(event.cart, event.id, event.index);
    });
  }

  ngOnInit() {
    this.currentUser =  this.utilService.getAuthUser();
    console.log('Userr ', this.currentUser);
    if (this.currentUser) {
      this.getCartItems();
    } else {
      this.getOfflineCart();
    }
  }

  getCartItems() {
    this.loading = true;
    this.userService.getCartItems({customer_id: this.currentUser.customer_id})
      .subscribe((res: IResponse) => {
        console.log('Res Cart ', res);
        this.cartItems = res.data;
        this.loading = false;
        this.cartItems.forEach((cart, i) => {
          // cart.total_ = parseFloat(cart.price) * parseInt(cart.quantity, 10);
          this.totalInCart += parseFloat(cart.total);
        });
      }, error => {
        console.log('Cart Error ', error);
        this.loading = false;
      });
  }

  getOfflineCart() {
    const oldCart = JSON.parse(this.cacheService.getSession('CART')) || [];
    this.totalInCart = 0;
    this.cartItems_ = oldCart.slice(0, 3);
    oldCart.forEach((cart, i) => {
      this.totalInCart += (parseFloat(cart.price) * parseInt(cart.quantity, 10));
    });
    this.cartItems = oldCart;
    console.log('CART FROM CACHE', oldCart, this.totalInCart)
    this.loading = false;
  }
  removeFromCart(cart, index=null) {
    console.log('Cart ', cart);
    if(!this.currentUser) {
      const oldCart = JSON.parse(this.cacheService.getSession('CART')) || [];
      oldCart.splice(index, 1);
      this.cacheService.setSession('CART', JSON.stringify(oldCart) || []);
      // this.getCartItems();
      window.location.assign(window.location.href);

    } else {
      this.userService.removeCart({customer_id: this.currentUser.customer_id,
        product_id: cart.product_id}).subscribe((res: IResponse) => {
        // this.getCartItems();
        window.location.assign(window.location.href);
      }, error => {
        this.alertService.error(error.error.message || 'Unable to remove product');
      });
    }
  }
  clearCart() {
    if(!this.currentUser) {
      this.cacheService.deleteSession('CART');
    } else {
      this.userService.clearCart(this.currentUser.customer_id).subscribe((res: IResponse) => {
        window.location.assign(window.location.href);
      }, error => {
        this.alertService.error(error.error.message || 'Unable to clear cart');
      });
    }
  }
  prepareCartAndLogin() {
    this.alertService.info('Sign up or login to checkout your cart!');
    this.navigatorService.navigateUrl('/login');
  }
  updateCart(cart, id, index) {
    console.log('Cart ', cart);
    if(!this.currentUser) {
      const qty: any = $('#' + id).val() || 0;
      cart.total = (parseFloat(cart.price) * parseInt(qty, 10));
      const cartItems = JSON.parse(this.cacheService.getSession('CART'));
      const _cart = {...cart, quantity: qty, total: parseFloat(cart.price) * parseInt(qty, 10)};
      cartItems[index] = _cart;
      this.cacheService.setSession('CART', JSON.stringify(cartItems));
      console.log('____ cart ', _cart, index, cartItems[index]);
      this.eventService.broadcast('GET_CARTS', cartItems);
      this.getOfflineCart();
    } else {
      const qty: any = $('#' + id).val() || 0;
      cart.total = (parseFloat(cart.price) * parseInt(qty, 10));
      this.userService.updateCart({
        product_id: cart.product_id,
        quantity: qty,
        customer_id: cart.customer_id
      }).subscribe((res: IResponse) => {
        // cart.total = parseFloat(res.data.total);
        this.totalInCart = 0;
        this.cartItems.forEach((_cart, i) => {
          // cart.total_ = parseFloat(cart.price) * parseInt(cart.quantity, 10);
          this.totalInCart += parseFloat(_cart.total);
        });
        this.eventService.broadcast('GET_CARTS')
      });
    }
  }
  validateQty(cart, id, index=null) {
    this.updateCart(cart, id, index);
  }
  moveToWishList(cart) {
    this.utilService.addToWishlist({product: {
      product_id: cart.product_id
    }});
    this.removeFromCart(cart);
  }

}
