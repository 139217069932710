import { Component, OnInit } from '@angular/core';
import {UtilService} from '../../services/utilService/util.service';
import {UserService} from '../../services/apiCalls/userService/user.service';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {IResponse} from '../../interfaces/iresponse';

@Component({
  selector: 'app-my-addressbooks',
  templateUrl: './my-addressbooks.component.html',
  styleUrls: ['./my-addressbooks.component.css']
})
export class MyAddressbooksComponent implements OnInit {
  breadCrumb = null;
  addressBooks = [];
  currentUser = null;
  loaders = {
    saving: false
  };
  editAddress = null;
  shipping = {
    customer_id: null,
    title: 'Home',
    firstname: null,
    lastname: null,
    address: null,
    // country: 156,
    city: null,
    status: 1,
    // zone: 2412,
    phonenumber: null,
    shippingarea_id: 1
  };
  loading = true;
  shippingAreas = [];
  constructor(private utilService: UtilService, private userService: UserService,
              private alertService: BootstrapNotifyService) { }

  ngOnInit() {
    this.currentUser = this.utilService.getAuthUser();
    this.breadCrumb  = {
      name: 'Address Books',
      parent: 'Home',
      subLink: null
    };
    this.getAddressBooks();
    this.getShippingArea();
  }

  getAddressBooks() {
    this.loading = true;
    this.addressBooks = [];
    this.userService.getAddressBooks(this.currentUser.customer_id)
      .subscribe((res: IResponse) => {
      this.loading = false;
        console.log('Res Books Address ', res);
        this.addressBooks = res.data || [];
      }, error => {
      this.alertService.error(error.error.message || 'Unable to fetch address books!');
        this.loading = false;
      });
  }

  getShippingArea() {
    this.userService.getShippingAreas().subscribe((res: IResponse) => {
      console.log('res ', res);
      this.shippingAreas = res.data;
    }, error => {
      console.log({error});
    });
  }
  createShippingAddress() {
    console.log('Create address ', this.shipping);
    if (!this.shipping.phonenumber) {
      return this.alertService.error('Mobile phone address is required!');
    } else if (!this.shipping.firstname) {
      return this.alertService.error('First name is required!');
    } else if (!this.shipping.lastname) {
      return this.alertService.error('Last name is required!');
    } else if (!this.shipping.address) {
      return this.alertService.error('Address is required!');
    } else if (this.editAddress) {
      this.loaders.saving = true;
      this.updateAddress();
    } else {
      this.shipping.customer_id = this.currentUser.customer_id;
      this.loaders.saving = true;
      this.userService.createAddress(this.shipping).subscribe((res: IResponse) => {
        console.log({res});
        this.loaders.saving = false;
        this.alertService.success('Shipping address added successfully');
        this.resetShipping();
        this.getAddressBooks();
        this.utilService.closeModal('addressBooks');
      }, error => {
        console.log({error});
        this.loaders.saving = false;
        this.alertService.error(error.error.message || 'Unable to add shipping address');
      });

    }
  }
  updateAddress() {
    this.shipping.customer_id = this.currentUser.customer_id;
    this.userService.updateAddress(this.shipping).subscribe((res: IResponse) => {
      console.log({res});
      this.loaders.saving = false;
      this.alertService.success('Shipping address updated successfully');
      this.resetShipping();
      this.getAddressBooks();
      this.editAddress = null;
      this.utilService.closeModal('addressBooks');
    }, error => {
      console.log({error});
      this.loaders.saving = false;
      this.alertService.error(error.error.message || 'Unable to update shipping address');
    });
  }

  editShippingAddress(address) {
    this.resetShipping();
    this.editAddress = this.shipping = JSON.parse(JSON.stringify(address));
    this.utilService.openModal('addressBooks');
    this.utilService.closeModal('listAddressBooks');
  }
  deleteShippingAddress(address) {
    console.log({address});
    this.utilService.confirmAction(() => {
      this.userService.deleteShippingAddress(address.id).subscribe((res: IResponse) => {
        this.alertService.success(res.message || 'Shipping address deleted successfully');
      }, error => {
        console.log({error});
        this.alertService.error(error.error.message);
      });
    });
  }

  openAddressModal(id) {
    this.utilService.openModal(id);
  }
  resetShipping() {
    this.shipping = {
      customer_id: null,
      title: 'Home',
      firstname: null,
      lastname: null,
      address: null,
      // country: 156,
      city: null,
      status: 1,
      // zone: 2412,
      phonenumber: null,
      shippingarea_id: 1
    };
  }
}
