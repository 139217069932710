import { Component, OnInit } from '@angular/core';
import {UtilService} from '../../services/utilService/util.service';
import {UserService} from '../../services/apiCalls/userService/user.service';
import {IResponse} from '../../interfaces/iresponse';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.css']
})
export class MyOrdersComponent implements OnInit {
  breadCrumb = null;
  currentUser = null;
  loading = true;
  orderList = [];
  orderDetails = null;
  loadingDetails = false;
  changeOccured = true;
  constructor(private utilService: UtilService,
              private alertService: BootstrapNotifyService,
              private userService: UserService) { }

  ngOnInit() {
    this.breadCrumb  = {
      name: 'Orders',
      parent: 'Home',
      subLink: null
    };
    this.currentUser =  this.utilService.getAuthUser();
    this.getOrderLists();
  }
  getOrderLists() {
    this.loading = true;
    this.orderList  = [];
    this.changeOccured = false;
    this.userService.getOrderLists(this.currentUser.customer_id)
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.orderList = res.data;
        this.loading = false;
        this.utilService.startDatatable('customer-orders');
        this.changeOccured = true;
      }, error => {
        this.orderList = [];
        console.log('Error ', error);
        this.utilService.startDatatable('customer-orders');
        this.loading = false;
        this.changeOccured = true;
      });
  }
  showDetails(order, products) {
    this.loadingDetails = true;
    this.utilService.openModal('ShowDetails');
    this.orderDetails = { order, products };
    this.orderDetails.subTotal = ( parseFloat(this.orderDetails.order.total || '0') - parseFloat(this.orderDetails.order.shipping_fee || 0) - parseFloat(this.orderDetails.order.coupon_dicount || 0) );
    // console.log(' ++++',  parseFloat(this.orderDetails.order.total), this.orderDetails.order.total, this.orderDetails.order.shipping_fee, this.orderDetails.order.coupon_dicount, 'sub ', this.orderDetails.subTotal);

    /* this.userService.getOrderDetails(order.order_id).subscribe((res: IResponse) => {
       this.orderDetails = res.data;
       this.loadingDetails = false;
     }, error => {
       console.log({error});
       this.alertService.error(error.error.message || 'Unable to fetch order details');
       this.utilService.closeModal('ShowDetails');
       this.loadingDetails = false;
     });*/
  }
}
