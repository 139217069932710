// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // API_URL: 'http://162.241.156.154/~uplcommerce/api',
  API_URL: 'https://laternabooks.ng/late2021rna/public/api',
  API_VERSION: 'v1',
  JWT_KEY: '',
  TOKEN: 'hj6545tyhertyuew2345678888ujgfe3234567880000000000$&^%$0ToKENiiijyyH35Yyr6790-tgh45678ij',
  PRIVATE_KEY: 'Rider-POInt-0f-Sale-hj6545tyhertyuew2345678888ujgfe323456788000000000000000iiiiiiiiijyyH35Yyr6790-tgh45678ij',
  USERTOKEN: '4sW_udEk5FMqHZGsrPiykafaa973757067sW_uddEk5',
  USERROLE: '4sW_udEk5FMqHZGsrPiykafaa97375754ghjk067sW_uddEk5',
  FIREBASE: {
    apiKey: 'AIzaSyCCth3nJIjkFHqhpwwgumeoGb5ZEWuZPpA',
    authDomain: 'am-alive-b3a6d.firebaseapp.com',
    databaseURL: 'https://am-alive-b3a6d.firebaseio.com',
    projectId: 'am-alive-b3a6d',
    storageBucket: 'am-alive-b3a6d.appspot.com',
    messagingSenderId: '450657171750',
    appId: '1:450657171750:web:ab4c521866c53a811ca9e7',
    measurementId: 'G-5X6PSH5K4N'
  },
  URL_VALIDATION:  /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi,
  // tslint:disable-next-line:max-line-length
  EMAIL_VALIDATION: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  SECRET_KEY: '4sW_udEk5FMqHZGsrPiykafaa973757067sW_uddEk5-Aroko-yuolalkanaFMqHZGsrPiykafaaMqHZGsrPiykafaatjqebjf',
  // tslint:disable-next-line:max-line-length
  SECRET_KEY_TOKEN: '4sW_udEk5FMqHZGsrPiykafaa973757067sW_uddEk5-Aroko-yulalkanaFMqHZGsrPiykafaaMqHZGsrPiykafaatjqebjf-2019-sidPOKJHj7ikj-o890pojrt9-2tred;sdfguytr08j-',
  SECRETENTITY: '4sW_udEk5FMqHZGsrPiykafaa973757067sW_uddEk5FsW_udEk5FMqHZGsrPiykafaaMqHZGsrPiykafaatjqebjf',
  // tslint:disable-next-line:max-line-length
  ACTION_KEY: '12384-09875poiuyty-987to890pojrt9-2976try-2019-sidPOKJHj7ikj-o890pojrt9-2tred;sdfguytr08j-987to890pojrt9-2tred;sdfguytr08j-arokoyuolalekan',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
