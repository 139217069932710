import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {NotificationService} from '../../../services/notificationServices/notification.service';
import {ValidationErrorService} from '../../../services/validation-error-service/validation-error.service';
import {EventsService} from '../../../services/eventServices/event.service';
import {NavigatorService} from '../../../services/navigatorService/navigator.service';
import {EncryptDataService} from '../../../services/encryption/encrypt-data.service';
import {CacheService} from '../../../services/cacheService/cache.service';
import {GuardService, RoleService} from '../../../services/gaurdService/guard.service';
import {NotifyComponent} from '../../components/notify/notify.component';
import {SidebarComponent} from '../../layout/dashbord/sidebar/sidebar.component';
import {FooterComponent} from '../../layout/dashbord/footer/footer.component';
import {HeaderComponent} from '../../layout/dashbord/header/header.component';
import {DecryptService} from '../../../services/decryptService/decrypt.service';
import {NgSelectModule} from '@ng-select/ng-select';
import {PageNotFoundComponent} from '../../components/page-not-found/page-not-found.component';
import {AsterisksPipe} from '../../pipes/Asterisks/asterisks.pipe';
import {ChangePasswordComponent} from '../../components/change-password/change-password.component';
import {BreadcrumbComponent} from '../../layout/dashbord/breadcrumb/breadcrumb.component';
import {SparklineComponent} from '../../components/sparkline/sparkline.component';
// import { TrendModule } from 'ngx-trend';
import {NavBarComponent} from "../../layout/landing/nav-bar/nav-bar.component";
import {FeatureProductsComponent} from "../../components/feature-products/feature-products.component";
import {RightSiderComponent} from "../../layout/right-sider/right-sider.component";
import {RedirectService} from "../../../services/redirectService/redirect.service";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    RouterModule,
    // TrendModule
  ],
  declarations: [
    NotifyComponent,
    SidebarComponent,
    PageNotFoundComponent,
    FooterComponent,
    BreadcrumbComponent,
    HeaderComponent,
    ChangePasswordComponent,
    SparklineComponent,
    NavBarComponent,
    AsterisksPipe,
    FeatureProductsComponent,
    RightSiderComponent,

  ],
  entryComponents: [],
  providers: [
    NotificationService,
    ValidationErrorService,
    EventsService,
    NavigatorService,
    DecryptService,
    GuardService,
    EncryptDataService,
    CacheService,
    DecryptService,
    RoleService,
    RedirectService
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NotifyComponent,
    SidebarComponent,
    NavBarComponent,
    PageNotFoundComponent,
    SparklineComponent,
    FooterComponent,
    BreadcrumbComponent,
    ChangePasswordComponent,
    HeaderComponent,
    FeatureProductsComponent,
    RightSiderComponent,
    AsterisksPipe,
    // TrendModule,
    NgSelectModule
  ]
})
export class SharedModules { }
