import {Component, OnInit} from '@angular/core';
import { environment as ENV } from '../../../environments/environment';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {IResponse} from '../../interfaces/iresponse';
import {NavigatorService} from '../../services/navigatorService/navigator.service';
// import * as JWT_DECODE from 'jwt-decode';
import {CacheService} from '../../services/cacheService/cache.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/authService/auth.service';
// import {SidebarComponent} from '../../shared/layout/dashbord/sidebar/sidebar.component';
import {EventsService} from '../../services/eventServices/event.service';
import {UserService} from '../../services/apiCalls/userService/user.service';
import {UtilService} from '../../services/utilService/util.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public EMAIL_VALIDATION: any =  ENV.EMAIL_VALIDATION;
  public credentials = {
    email: null,
    password: null
  };
  private user = null;
  public userDetails: any;
  loaders = {
    login: false,
    showResetLink: false
  };
  constructor(private bootstrapNotify: BootstrapNotifyService,
              private userService: UserService,
              private cacheService: CacheService,
              private navigateService: NavigatorService,
              private utilService: UtilService,
              private router: Router,
              private activeRoute: ActivatedRoute,
              private eventService: EventsService,
              private authService: AuthService) {
    const newsLetter = this.cacheService.getSession('newLetterPopup');
    const wished_product = this.cacheService.getSession('WISHLIST');
    const cart_product = this.cacheService.getSession('CART');
    this.authService.logOut();
    this.cacheService.setSession('newLetterPopup', newsLetter);
    this.cacheService.setSession('WISHLIST', wished_product);
    this.cacheService.setSession('CART', cart_product);
    this.eventService.on('SOCIAL_SIGNIN', (user) => {
      console.log('process login', user);
      this.user = user;
      this.socialLogin();
    });
/*
    this.eventService.on('SOCIAL_SIGNUP', (user) => {
      console.log('process signup', user);
      this.navigateService.navigateUrl('/register');
      setTimeout(() => {
        this.eventService.broadcast('SOCIAL_SIGNUP', user);
      }, 1000);
    });*/

  }
  ngOnInit(): void {
    this.userDetails = null;
    console.log('IN PROGRESS');
  }
  public loginProcess() {
    this.loaders.login = true;
    this.cacheService.deleteSession(ENV.TOKEN);
    this.cacheService.deleteStorage(ENV.TOKEN);
    if (!this.credentials.email || !this.credentials.password) {
      this.bootstrapNotify.info('Provide login details!');
      this.loaders.login = false;
    } else {
      this.userService.auth(this.credentials).subscribe((response: IResponse) => {
        console.log('Response', response);
        this.loaders.login = false;
        if (response.data.role.toLowerCase() !== 'customer') {
          this.bootstrapNotify.error('Account is not registered as laterna customer');
          this.authService.logOut();
        } else {
          const wished_product = JSON.parse(this.cacheService.getSession('WISHLIST')) || null;
          const cart_product = JSON.parse(this.cacheService.getSession('CART')) || [];
          this.bootstrapNotify.success(response.message || 'Login successful!');
          if (cart_product.length || wished_product) {
            this.utilService.addBulkToCart(() => {
              this.utilService.addToWishlist(wished_product);
              this.cacheService.deleteSession('WISHLIST');
            });
          } else {
            this.navigateService.navigateUrl(`/user/dashboard`);
          }
        }
      }, error => {
        this.bootstrapNotify.error(error.error && error.error.message || 'Unable to login', 'right');
        this.loaders.login = false;
        console.info('Error => ', error);
      });
    }
  }
  public socialLogin(user = this.user) {
    console.log('User 1', user);
    this.loaders.login = true;
    this.cacheService.deleteSession(ENV.TOKEN);
    this.cacheService.deleteStorage(ENV.TOKEN);
    /*this.credentials = {
      email: null,
      password: null
    };*/
    console.log('User 2', user);
    this.userService.social_login({
      access_token: user.access_token,
      idToken: user.idToken, signInToken: user.authToken,
      auth_type: user.auth_type
    }).subscribe((response: IResponse) => {
      console.log('Response', response);
      this.loaders.login = false;
      if (response.data.role.toLowerCase() !== 'customer') {
        this.bootstrapNotify.error('Account is not registered as laterna customer');
        this.authService.logOut();
      } else {
        const wished_product = JSON.parse(this.cacheService.getSession('WISHLIST')) || null;
        const cart_product = JSON.parse(this.cacheService.getSession('CART')) || [];
        this.bootstrapNotify.success(response.message || 'Login successful!');
        if (cart_product.length || wished_product) {
          this.utilService.addBulkToCart(() => {
            this.utilService.addToWishlist(wished_product);
            this.cacheService.deleteSession('WISHLIST');
          });
        } else {
          this.navigateService.navigateUrl(`/user/dashboard`);
        }
      }
    }, error => {
      this.bootstrapNotify.error(error.error && error.error.message || 'Unable to login', 'right');
      this.loaders.login = false;
      console.info('Error => ', error);
    });
  }
  public openUrl() {
    this.navigateService.navigateUrl('/user');
  }
}
