import {Injectable, OnInit} from '@angular/core';
import * as JWT_DECODE from 'jwt-decode';
import {CacheService} from '../cacheService/cache.service';
import {environment as ENV} from '../../../environments/environment';
import {IResponse} from '../../interfaces/iresponse';
import swal from 'sweetalert2';
import {EventsService} from '../eventServices/event.service';
import {UserService} from '../apiCalls/userService/user.service';
import {NavigatorService} from "../navigatorService/navigator.service";
import {BootstrapNotifyService} from "../bootstrap-notify/bootstrap-notify.service";
import {Title} from "@angular/platform-browser";
@Injectable({
  providedIn: 'root'
})
export class UtilService implements OnInit {
  dataTableInstance: any;
  Roles: any[] = [];
  mobile = false;
  currentUser = null;
  // public CASHIER_ROUTE: any[] = [];
  constructor(private cacheService: CacheService, private eventService: EventsService, private userService: UserService,
              private navigateService: NavigatorService,
              private titleService: Title,
              private navigatorService: NavigatorService, private alertService: BootstrapNotifyService) {
    this.eventService.on('NEW_LOGIN', () => {
      this.Roles = [];
    });
  }
  ngOnInit () {
    this.currentUser = this.getAuthUser();
  }

  public getAuthUser() {
    return JSON.parse(sessionStorage.getItem(ENV.USERTOKEN)) || null;
  }
  public getAuthUserRole() {
    return (JSON.parse(sessionStorage.getItem(ENV.USERROLE)) || 'user').toLowerCase();
  }
  public setRoles() {
    return this.Roles = [this.getAuthUserRole().toLowerCase()];
    // const user = ;
    /* if (user) {
       /!*user.roles.forEach((role) => {
         this.Roles.push(role.name);
       });*!/
       return this.Roles;
     } else {
       return this.Roles;
     }*/
  }
  public checkPrivilegeNow(users) {
    const Roles = [];
    const user = this.getAuthUser();
    if (user && user.roles) {
      user.roles.forEach((role) => {
        Roles.push(role.name);
      });
    }
    for (const userL of users) {
      if (Roles.includes(userL)) {
        return true;
      }
    }
    return false;
  }
  public shuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  public processCommonJs2() {



  }
  public openModal(id) {
    setTimeout(() => {
      (<any>$('#' + id)).modal({show: true, backdrop: 'static', keyboard: false});
    }, 20);
  }
  public closeModal(id) {
    (<any>$('#' + id)).modal('hide');
  }
  startDatatable(id) {
    setTimeout(() => {
      this.initDataTable(id);
    }, 1500);
  }

  responsiveTable(id, res) {
    setTimeout(() => {
      this.initDataTable(id, res);
    }, 1000);
  }

  public initDataTable(id, responsive = true) {
    if (this.dataTableInstance) {
      // console.log('DESTROYER ', this.dataTableInstance);
      // this.dataTableInstance.destroy();
    }
    const buttons = ['pdf', 'print', 'excel', 'csv', 'copy'];
    setTimeout(() => {
      this.dataTableInstance = ($('#' + id)as any).DataTable({
        pagingType: 'full_numbers',
        dom: 'tip',
        keys: !0,
        buttons: buttons,
        // order: [[1, 'asc']],
        language: {
          search: '_INPUT_',
          searchPlaceholder: 'Search...',
          paginate: {
            previous: '<i class=\'fas fa-angle-left\'>',
            next: '<i class=\'fas fa-angle-right\'>'
          }
        },
        select: {
          // style: 'multi'
        },
        columnDefs: [ {
          targets: 'no-sort',
          orderable: false,
        },
          { responsivePriority: 1, targets: 0 },
          { responsivePriority: 2, targets: -1 }],
        'lengthMenu': [
          [50, 100, 150, -1],
          [50, 100, 150, 'All']
        ],
        responsive: responsive,
      });
      $('.dt-buttons .btn').removeClass('btn-secondary').addClass('btn-sm btn-primary');
      // Add event listener for opening and closing details
      $(`#${id} tbody`).on('click', 'td.details-control', (e) => {
        const tr = (<any>$(this)).closest('tr');
        const row = this.dataTableInstance.row( tr );
        const target = $(`#${e.target.id}`);
        if ( row.child.isShown() ) {
          this.handleIconSwitch(target);
          tr.removeClass('shown');
          $('.dtr-details').addClass('table-bordered table-hover table-striped');

        } else {
          this.handleIconSwitch(target);
          tr.addClass('shown');
          $('.dtr-details').addClass('table-bordered table-hover table-striped');

        }
      });
    }, 400);

  }
  public handleIconSwitch(target) {
    if (target.hasClass('isShown')) {
      target.removeClass('isShown');
      target.addClass('isNotShown');
    } else {
      target.addClass('isShown');
      target.removeClass('isNotShown');
    }
  }
  public confirmAction(callback) {
    const swalWithBootstrapButtons = swal.mixin({
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      buttonsStyling: false,
    });
    swalWithBootstrapButtons({
      title: 'Are you sure?',
      text: `You won't be able to revert action! `,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        callback();
      } else if (
        // Read more about handling dismissals
      result.dismiss === swal.DismissReason.cancel
      ) {
        console.log('Action not completed!');
      }
    });
  }
  public actionRequireLogout(callback) {
    const swalWithBootstrapButtons = swal.mixin({
      confirmButtonClass: 'btn btn-success mx-2',
      cancelButtonClass: 'btn btn-danger',
      buttonsStyling: false,
    });
    swalWithBootstrapButtons({
      title: 'Are you sure?',
      text: `This action requires the system to log you out!`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, proceed!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        callback();
      } else if (
        // Read more about handling dismissals
      result.dismiss === swal.DismissReason.cancel
      ) {
        console.log('Action not completed!');
      }
    });
  }
  addToWishlist(product) {
    if(!product) {
      return false;
    }
    this.currentUser  = this.getAuthUser();
    console.log('Product ', product, this.currentUser);
    if (!this.currentUser) {
      this.cacheService.setSession('WISHLIST', JSON.stringify({product}));
      this.alertService.info('Sign up or login to continue order processing');
      this.navigatorService.navigateUrl('/login');
    } else {
      this.userService.addToWishList({
        product_id: product.product.product_id, customer_id: this.currentUser.customer_id})
        .subscribe((res: IResponse) => {
          console.log('Res ', res);
          this.alertService.success('Item added to wishlist!');
        }, error => {
          this.alertService.error(error.error.message);
          console.log('Erro ', error);
        });
    }
  }
  addBulkToCart(cb){
    const cachedCart = JSON.parse(this.cacheService.getSession('CART')) || [];
    for(let cart of cachedCart) {
      console.log('Cart in Cache ', cart);
      if(cart && cart.product) {
        this.addToCart(cart.product, cart.quantity, null, 'yes');
      } else {
        console.log('Invalid product');
      }
    }
    cb();
    this.navigateService.navigateUrl(`/my-cart`);
    this.cacheService.deleteSession('CART');
  }
  addToCart(product, quantity = 1, cb = null, bulk=null) {
    this.currentUser  = this.getAuthUser();

    if (!this.currentUser) {
      // e.preventDefault();
      console.log('Product ', product);
      const oldCart = JSON.parse(this.cacheService.getSession('CART')) || [];
      oldCart.push({product, quantity: quantity || 1, price: product.stock.selling_price || 0,
        product_name: product.product.product_name, image: product && product.images[0] && product.images[0].image,
        total: parseFloat(product.stock.selling_price) * quantity
      });
      this.cacheService.setSession('CART', JSON.stringify(oldCart));
      this.eventService.broadcast('ADDEDTOCART');
      this.showModal();
      // this.alertService.info('Sign up or login to continue order processing');
      // this.navigatorService.navigateUrl('/login');
    } else {
      this.userService.addToCart({
        product_id: product.product.product_id,
        customer_id: this.currentUser.customer_id,
        product_type: parseInt(product.product.product_type, 10),
        quantity
      })
        .subscribe((res: IResponse) => {
        console.log('Res ', res);
        if(!bulk) {
          this.eventService.broadcast('ADDEDTOCART');
          this.showModal();
        }
        if(cb) {cb();}
      }, error => {
        this.alertService.error(error.error.message);
        console.log('Erro ', error);
      });
    }
  }
  addToCartWithoutModal(product, quantity = 1, cb = null, bulk=null) {
    this.currentUser  = this.getAuthUser();

    if (!this.currentUser) {
      // e.preventDefault();
      console.log('Product ', product);
      const oldCart = JSON.parse(this.cacheService.getSession('CART')) || [];
      oldCart.push({product, quantity: quantity || 1, price: product.stock.selling_price || 0,
        product_name: product.product.product_name, image: product && product.images[0] && product.images[0].image,
        total: parseFloat(product.stock.selling_price) * quantity
      });
      this.cacheService.setSession('CART', JSON.stringify(oldCart));
      this.eventService.broadcast('ADDEDTOCART');
      // this.showModal();
      // this.alertService.info('Sign up or login to continue order processing');
      // this.navigatorService.navigateUrl('/login');
    } else {
      this.userService.addToCart({
        product_id: product.product.product_id,
        customer_id: this.currentUser.customer_id,
        product_type: parseInt(product.product.product_type, 10),
        quantity
      })
        .subscribe((res: IResponse) => {
        console.log('Res ', res);
        if(!bulk) {
          this.eventService.broadcast('ADDEDTOCART');
          // this.showModal();
        }
        if(cb) {cb();}
      }, error => {
        this.alertService.error(error.error.message);
        console.log('Erro ', error);
      });
    }
  }
  showModal() {
    // data-toggle="modal" data-target="#addCartModal"
    // this.openModal('');
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
    $('body').on('click', '.btn-add-cart', function(e) {
      (<any>$('#addCartModal')).modal({show: true, backdrop: 'static', keyboard: false});
      $('.add-cart-box #productImage').attr('src', $(this).parents('.product-default').find('figure img').attr('src'));
      $('.add-cart-box #productTitle').text($(this).parents('.product-default').find('.product-title').text());

     /* if ($('.sticky-header.fixed').css('margin-right') && !this.mobile) {
        const newMargin = Number($('.sticky-header.fixed').css('margin-right').slice(0, -2)) + 17 + 'px';

        $('.sticky-header.fixed').css('margin-right', newMargin);
        $('.sticky-header.fixed-nav').css('margin-right', newMargin);
        $('#scroll-top').css('margin-right', newMargin);
      }*/
    });
  }
  public setPageTitle(title, author=null) {
    if(!title) {
      return;
    }
    let newTitle = `${title} - Laterna Books`;
    if(author) {
      newTitle = `${title}-${author} - Laterna Books`;
    }
    this.titleService.setTitle(newTitle);
  }
}
