import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NavigatorService} from '../../../../services/navigatorService/navigator.service';
import {BootstrapNotifyService} from '../../../../services/bootstrap-notify/bootstrap-notify.service';
import { environment as ENV } from '../../../../../environments/environment';
import {IResponse} from '../../../../interfaces/iresponse';
import {UserService} from "../../../../services/apiCalls/userService/user.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, AfterViewInit {
  subscribe: string =  null;
  EMAIL_VALIDATION = ENV.EMAIL_VALIDATION;
  constructor(private navigate: NavigatorService,
              private notifyService: BootstrapNotifyService,
              private userService: UserService) { }

  ngOnInit() {
  }
  ngAfterViewInit() {  }
  public navigateToPage() {
    this.navigate.navigateUrl('/user/dashboard');
  }


}
